import { exist } from "@ki2/utils";

import { useNavigatorLanguage, useCurrentRoute } from "./browserUtils";

const ALLOWED_LANG: string[] = ["en", "fr"];

export type AppLanguages = "en" | "fr";

export function isAppLanguage(x: string): x is AppLanguages {
  return ALLOWED_LANG.includes(x);
}

export function useDefaultLanguage(defaultLanguage: AppLanguages = "en") {
  const navLang: string = useNavigatorLanguage() ?? "en-US";
  const lang: string = navLang.toLowerCase().split("-")[0];

  if (isAppLanguage(lang)) {
    return lang;
  }

  return defaultLanguage;
}

interface ILangRoute {
  lang: AppLanguages | null;
  route: string;
}

export function useLangRouteGetter() {
  const curRoute = useCurrentRoute() ?? "";

  return (route?: string | undefined | null): ILangRoute => {
    if (!exist(route)) {
      route = curRoute;
    }

    if (route.startsWith("/")) {
      route = route.substring(1);
    }

    const items = route.split("/");

    let ret: ILangRoute = {
      lang: null,
      route: "",
    };

    let routeLang = items[0];
    if (isAppLanguage(routeLang)) {
      ret.lang = routeLang;
      ret.route = items.slice(1).join("/");
    } else {
      ret.route = items.join("/");
    }

    if (ret.route.length > 0 && !ret.route.startsWith("/")) {
      ret.route = "/" + ret.route;
    }

    if (ret.route.endsWith("/")) {
      ret.route = ret.route.substring(0, ret.route.length - 1);
    }

    return ret;
  };
}

export function useLangRoute(route?: string | undefined | null): ILangRoute {
  const getLangRoute = useLangRouteGetter();
  return getLangRoute(route);
}

export function useCurrentLanguage(
  forcedLang?: AppLanguages | undefined | null
) {
  const defaultLanguage = useDefaultLanguage();
  const routeLang = useLangRoute().lang;

  return forcedLang ?? routeLang ?? defaultLanguage;
}

export function useLangRouteGenerator(
  genLanguage?: AppLanguages | null | undefined
) {
  const currentLang = useCurrentLanguage(genLanguage);

  return (
    route: string,
    routeLanguage: AppLanguages | null | undefined = null
  ) => {
    routeLanguage = routeLanguage ?? currentLang;

    if (!route.startsWith("/")) {
      route = "/" + route;
    }
    return `/${routeLanguage}${route}`;
  };
}
