import { ReactNode } from "react";

interface INavMobileMenuProps {
  subClassName?: string;
  onClick: () => void;
  label: string;
  children: ReactNode;
}

export function NavMobileMenu({
  subClassName,
  onClick,
  label,
  children,
}: INavMobileMenuProps) {
  return (
    <div className="navbar-mobile-menu">
      <div className="menu-button" onClick={onClick}>
        <b>☰ {label}</b>
      </div>
      <div className={subClassName}>{children}</div>
    </div>
  );
}

interface INavMobileMenuItemProps {
  href: string;
  label: string;
  icon: ReactNode;
}

export function NavMobileMenuItem({
  href,
  label,
  icon,
}: INavMobileMenuItemProps) {
  return (
    <a href={href} className="navlink">
      {label} {icon}
    </a>
  );
}
