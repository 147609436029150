import { AdminPanelSettings } from "@mui/icons-material";

import { PageLayout, Section, ResponsiveTable } from "@components";

import "@styles/Page.scss";

export default function GuideRoles() {
  return (
    <PageLayout
      title="Rôles et droits"
      titleIcon={<AdminPanelSettings />}
      titleClassName="guide"
      lang="fr"
    >
      <Section id="les-rôles" title="Les rôles">
        <p>
          Sur Dotter.science, chaque utilisateur bénéficie d'un ou plusieurs
          rôle(s) qui lui permettent d'accéder à certaines fonctionnalités. Les
          permissions sont donc restreintes selon votre rôle dans l'étude afin
          de garantir une sécurité optimale des données.
        </p>
        <p>
          Les rôles disponibles sont <b>Technicien d'étude clinique (TEC)</b>,{" "}
          <b>Attaché de Recherche Clinique (ARC)</b>, <b>Investigateur</b> et{" "}
          <b>Administrateur</b>.
        </p>
        <ResponsiveTable>
          <tr>
            <td>Rôle</td>
            <td>Description du rôle</td>
          </tr>
          <tr>
            <td>
              <b style={{ color: "#ffba00" }}>
                Technicien d'étude clinique (TEC)
              </b>
            </td>
            <td>
              Les TEC peuvent lister les patients de leur centre, y ajouter de
              nouveau patient (screening), enregistrer des formulaires et les
              verrouiller.
              <br />
              Les TEC+ ont accès complet à tous les centres. Ils ont les mêmes
              droits que les TEC, mais sur l’ensemble des patients de l’étude.
              Lorsqu’il ajoute un patient, les TEC+ peuvent sélectionner le
              centre de rattachement du patient.
            </td>
          </tr>
          <tr>
            <td>
              <b style={{ color: "#59df6f" }}>
                Attaché de Recherche Clinique (ARC)
              </b>
            </td>
            <td>
              Les ARC peuvent lister les patients de leur centre, mais ne
              peuvent pas en ajouter ni enregistrer de formulaires. Ils peuvent
              par contre ouvrir et fermer des queries, verrouiller et
              déverrouiller les formulaires ainsi que monitorer (ou démonitorer)
              les formulaires.
              <br />
              Les ARC coordonnateurs disposent des mêmes droits que les ARC mais
              sur l’ensemble des patients de l’étude. Ils peuvent par ailleurs
              réaliser un export de la base de données.
            </td>
          </tr>
          <tr>
            <td>
              <b style={{ color: "#9c1bcf" }}>Investigateur</b>
            </td>
            <td>
              Les investigateurs peuvent lister les patients de leur centre, en
              ajouter (screening) et enregistrer des formulaires. Ils peuvent
              aussi verrouiller et signer les formulaires.
              <br /> investigateurs coordonnateurs disposent des mêmes droits
              que les investigateurs, mais sur l’ensemble des patients de
              l’étude.
            </td>
          </tr>
          <tr>
            <td>
              <b style={{ color: "#fa383e" }}>Administrateur</b>
            </td>
            <td>
              Les administrateurs locaux (disposant d’un accès limité à un
              centre de rattachement) peuvent réaliser toutes les opérations (y
              compris l’administration et la conception de l’étude) à
              l’exception de l’export de la base de données.
              <br />
              Les administrateurs globaux (disposant d’un accès complet à tous
              les centres) et peuvent réaliser toutes les opérations (y compris
              l’export de la base de données).
            </td>
          </tr>
        </ResponsiveTable>
      </Section>

      <Section id="les-droits" title="Les droits">
        <p>
          Le tableau ci-dessous récapitule les permissions qu'ont chacun des
          rôles. Si un rôle dispose de la permission décrite, un symbole{" "}
          <b>✔</b> est indiqué.
        </p>
        <ResponsiveTable tableClassName="table-centered-cells">
          <tr className="vertical-headers">
            <td></td>
            <td>
              <div>TEC</div>
            </td>
            <td>
              <div>TEC+</div>
            </td>
            <td>
              <div>ARC</div>
            </td>
            <td>
              <div>ARC coordonnateur</div>
            </td>
            <td>
              <div>Investigateur</div>
            </td>
            <td>
              <div>Investigateur coordonnateur</div>
            </td>
            <td>
              <div>Administrateur local</div>
            </td>
            <td>
              <div>Administrateur global</div>
            </td>
          </tr>
          <tr>
            <td>Lister les patients de l'étude (ou de son centre)</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Ajouter un patient</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Enregistrer les données d'un formulaire</td>
            <td>✔</td>
            <td></td>
            <td>✔</td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>
              Ouvrir et fermer des queries (note : tous les utilisateurs peuvent
              répondre à une query)
            </td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Verrouiller un formulaire</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Déverrouiller un formulaire</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Monitorer un formulaire</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Démonitorer un formulaire</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Signer un formulaire</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Supprimer la signature d'un formulaire</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>
              Administrer l'étude (modifier les informations, la structure, les
              centres et les utilisateurs de l'étude)
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Demander un nouvel export et consulter la liste des exports</td>
            <td></td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
            <td>✔</td>
          </tr>
        </ResponsiveTable>
        <br />
      </Section>
    </PageLayout>
  );
}
