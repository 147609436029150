import startimg from "@content/tutorial/images/Launching-pana.svg";

import { Note, Section } from "@components";

import { AtoZLayout } from "@content/tutorial/a-to-z/Layout";

export default function TutorialAtoZStep1() {
  return (
    <AtoZLayout lang="en" idx={1}>
      <p>
        This tutorial will guide you in designing your first study from A to Z.
        You will then be able to set up some additional options using the{" "}
        <a href="/en/guides">
          <b>Guides</b>
        </a>{" "}
        and{" "}
        <a href="/en/advanced">
          <b>Advanced Features</b>
        </a>{" "}
        to refine your study so that it perfectly meets your needs.
      </p>
      <p>
        If you are the study manager, this tutorial is for you. You will need to
        have the role of <b>Administrator</b> (see the guide on{" "}
        <a href="/en/guides/roles" target="_blank">
          <b>Roles</b>
        </a>
        ). If your study already has a constructed eCRF and you wish to use it,
        please refer to the tutorial{" "}
        <a href="/en/tutorial/using-dotter">
          <b>Using Dotter.science in Daily Life</b>
        </a>
        .
      </p>
      <img src={startimg} alt="start" className="medium centered" />
      <Section title="Objective">
        <p>
          By the end of this tutorial, you will be able to:
          <li>manage the administrative information and users of your study</li>
          <li>
            establish a study schema that meets your needs (randomization mode,
            key steps, etc...)
          </li>
          <li>build complete and dynamic forms</li>
          <li>design a robust eCRF from the pre-established forms</li>
        </p>
        <Note type="important">
          To complete this tutorial, you must have obtained access to create a
          study from a Dotter.science administrator. If not, please request it
          by <a href="mailto:contact@dotter.science">clicking here</a>.
        </Note>
      </Section>
    </AtoZLayout>
  );
}
