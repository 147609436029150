import type { ReactNode } from "react";

interface IRootSelectorProps {
  children: ReactNode;
  title: string;
  subtitle: string;
}

export function RootSelector({
  children,
  title,
  subtitle,
}: IRootSelectorProps) {
  return (
    <div className="index-box">
      <div className="index-title">
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>
      <div className="index-paths">{children}</div>
    </div>
  );
}
