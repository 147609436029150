import startimg from "@content/tutorial/images/Launching-pana.svg";

import { Note, Section } from "@components";

import { UsingDotterLayout } from "@content/tutorial/using-dotter/Layout";

export default function TutorialUsingDotterStep1() {
  return (
    <UsingDotterLayout idx={1} lang="fr">
      <p>
        Ce tutoriel va vous permettre de comprendre les fonctionnalités de
        Dotter.science qui vous seront utiles pour compléter et manipuler l'eCRF
        de votre étude déjà en place. Il est nécessaire pour cela que votre
        étude soit à minima à l'état actif.
      </p>
      <p>
        Si vous êtes utilisateur de l'étude, ce tutoriel est fait pour vous. Si
        votre étude ne possède un eCRF déjà construit, que vous êtes
        administrateur de l'étude et que vous souhaitez le créer, dirigez-vous
        vers le tutoriel{" "}
        <a href="/fr/tutorial/a-to-z">
          <b>Concevoir une étude de A à Z</b>
        </a>
        .
      </p>
      <img src={startimg} alt="start" className="medium centered" />
      <Section title="Objectif">
        <p>
          À la fin de ce tutoriel, vous serez capable :
          <li>
            d'ajouter de nouveaux patients à l'étude, de les inclure et de les
            randomiser
          </li>
          <li>
            de parcourir l'eCRF mis en place pour ajouter des données à l'étude
          </li>
          <li>d'initialiser et visualiser des queries ou des événements</li>
          <li>d'exporter des données de l'étude</li>
        </p>
        <Note type="important">
          Pour effectuer ce tutoriel, vous devrez au préalable avoir obtenu
          l'accès à une étude par un administrateur de Dotter.science. Si ce
          n'est pas le cas, veuillez en faire la demande en{" "}
          <a href="mailto:contact@dotter.science">cliquant ici</a>
        </Note>
      </Section>
    </UsingDotterLayout>
  );
}
