import type { ReactNode } from "react";
import { Fragment } from "react";
import { exist } from "@ki2/utils";

import { ITutorialItem, useTranlations } from "@helpers";

import type { IPageLayoutProps } from "../Layout";
import { PageLayout } from "../Layout";

import { TutorialProgress } from "./TutorialProgress";
import { PreviousNext } from "./PreviousNext";

export interface ITutorialLayoutProps extends Omit<IPageLayoutProps, "title"> {
  selectedTutorial: "AtoZ" | "usingdotter";
  currentStepIndex: number;
  heroUrl?: string;
  heroAlt?: string;
}

export function TutorialLayout({
  selectedTutorial,
  currentStepIndex,
  lang,
  preBody,
  postBody,
  heroUrl,
  heroAlt,
  children,
  ...others
}: ITutorialLayoutProps) {
  const tkeys = useTranlations(lang);

  const steps = tkeys.tutorialsteps[selectedTutorial] ?? [];
  let currentStep: ITutorialItem | undefined;

  if (currentStepIndex > 0 && currentStepIndex <= steps.length) {
    currentStep = steps[currentStepIndex - 1];
  }

  const title = currentStep?.title ?? "Tutorial";

  const xPreBody: ReactNode = (
    <Fragment>
      {preBody}
      <TutorialProgress items={steps} currentIndex={currentStepIndex} />
    </Fragment>
  );

  const xPostBody = (
    <Fragment>
      {postBody}
      <PreviousNext currentIndex={currentStepIndex} items={steps} lang={lang} />
    </Fragment>
  );

  let xHero: ReactNode = undefined;
  if (exist(heroUrl)) {
    xHero = <img src={heroUrl} alt={heroAlt} className="small centered" />;
  }

  return (
    <PageLayout
      preBody={xPreBody}
      postBody={xPostBody}
      lang={lang}
      title={title}
      titleIcon={xHero}
      {...others}
    >
      {children}
    </PageLayout>
  );
}
