import startimg from "@content/tutorial/images/Launching-pana.svg";

import { Note, Section } from "@components";

import { AtoZLayout } from "@content/tutorial/a-to-z/Layout";

export default function TutorialAtoZStep1() {
  return (
    <AtoZLayout lang="fr" idx={1}>
      <p>
        Ce tutoriel va vous permettre de concevoir votre première étude de A à
        Z. Vous pourrez ensuite paramétrer quelques options supplémentaires à
        l'aide des{" "}
        <a href="/fr/guides">
          <b>Guides</b>
        </a>{" "}
        et des{" "}
        <a href="/fr/advanced">
          <b>Fonctionnalités avancées</b>
        </a>{" "}
        pour affiner votre étude afin qu'elle corresponde parfaitement à vos
        besoins.
      </p>
      <p>
        Si vous êtes gestionnaire de l'étude, ce tutoriel est fait pour vous.
        Vous devrez alors posséder le rôle d'<b>Administrateur</b> (voir le
        guide sur{" "}
        <a href="/fr/guides/roles" target="_blank">
          <b>les rôles</b>
        </a>
        ). Si votre étude possède un eCRF déjà construit et que vous souhaitez
        l'utiliser, dirigez-vous vers le tutoriel{" "}
        <a href="/fr/tutorial/using-dotter">
          <b>Utiliser Dotter.science au quotidien</b>
        </a>
        .
      </p>
      <img src={startimg} alt="start" className="medium centered" />
      <Section title="Objectif">
        <p>
          À la fin de ce tutoriel, vous serez capable :
          <li>
            de gérer les informations administratives et les utilisateurs de
            votre étude
          </li>
          <li>
            d'établir un schéma d'étude qui corresponde à vos besoins (mode de
            randomisation, étapes clés, etc...)
          </li>
          <li>de construire des formulaires complets et dynamiques</li>
          <li>
            de concevoir un eCRF robuste à partir des formulaires pré-établis
          </li>
        </p>
        <Note type="important">
          Pour effectuer ce tutoriel, vous devrez au préalable avoir obtenu
          l'accès à la création d'une étude par un administrateur de
          Dotter.science. Si ce n'est pas le cas, veuillez en faire la demande
          en <a href="mailto:contact@dotter.science">cliquant ici</a>
        </Note>
      </Section>
    </AtoZLayout>
  );
}
