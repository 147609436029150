import { useTranlations } from "@helpers";

import { PageLayout, FeatureBlock, AppIcon } from "@components";

import "@styles/SelectionPage.scss";

export default function AdvancedIndex() {
  const tkeys = useTranlations("en");

  let xguides = tkeys.guides.map((item) => (
    <FeatureBlock
      title={item.name}
      link={item.link}
      icon={<AppIcon name={item.icon} />}
    />
  ));

  return (
    <PageLayout title="Guides" defaultBodyClassName="features-root" lang="en">
      <div className="guides">{xguides}</div>
    </PageLayout>
  );
}
