import { useState, useEffect } from "react";
import { exist } from "@ki2/utils";

type WindowType = Window & typeof globalThis;

function _ssr_get_window(): WindowType | undefined {
  if (typeof window !== "undefined") {
    return window;
  }
  return undefined;
}

function _ssr_get_navigator(): Navigator | undefined {
  if (typeof navigator !== "undefined") {
    return navigator;
  }
  return undefined;
}

export function useWindow() {
  const [currentWindow, setCurrentWindow] = useState<WindowType | undefined>(
    _ssr_get_window()
  );

  useEffect(() => {
    console.log("[useWindow] update window");
    setCurrentWindow(_ssr_get_window());
  }, [_ssr_get_window()]);

  return currentWindow;
}

export function useHasWindow() {
  return exist(useWindow());
}

export function useNavigator() {
  const [currentNavigator, setCurrentNavigator] = useState<
    Navigator | undefined
  >(_ssr_get_navigator());

  useEffect(() => {
    console.log("[useNavigator] update navigator");
    setCurrentNavigator(_ssr_get_navigator());
  }, [_ssr_get_navigator()]);

  return currentNavigator;
}

export function useHasNavigator() {
  return exist(useNavigator());
}

export function useWithWindow<T = void>(
  fn: (window: Window & typeof globalThis) => T
): T | undefined {
  const curWindow = useWindow();

  if (exist(curWindow)) {
    return fn(curWindow);
  }

  return undefined;
}

export function useWithNavigator<T = void>(
  fn: (navigator: Navigator) => T
): T | undefined {
  const curNavigator = useNavigator();

  if (exist(curNavigator)) {
    return fn(navigator);
  }

  return undefined;
}

export function useShowable(showbelow: number = 0) {
  const [showable, setShowable] = useState<boolean>(false);
  const curWindow = useWindow();

  function handleShowable() {
    if (!exist(curWindow)) {
      setShowable(false);
    } else {
      setShowable(window.scrollY >= showbelow);
    }
  }

  useEffect(() => {
    if (exist(window)) {
      window.addEventListener(`scroll`, handleShowable);
      return () => window.removeEventListener(`scroll`, handleShowable);
    }
  }, []);

  return showable;
}

export function useLocation() {
  return useWithWindow((window) => window.location);
}

export function useNavigatorLanguage() {
  return useWithNavigator((navigator) => navigator.language);
}

export function useCurrentRoute() {
  return useLocation()?.pathname;
}

export function useIsClient() {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient;
}
