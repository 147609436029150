import studyexport from "@content/tutorial/images/Printer-amico.svg";
import ongletexport from "@content/tutorial/images/acces-export.png";
import nouvelexport from "@content/tutorial/images/nouvel-export.png";
import imprimer from "@content/advanced/images/imprimer.png";

import { Note, Section } from "@components";

import { UsingDotterLayout } from "@content/tutorial/using-dotter/Layout";

export default function TutorialUsingDotterStep6() {
  return (
    <UsingDotterLayout
      idx={6}
      lang="fr"
      heroUrl={studyexport}
      heroAlt="Exporter l'étude"
    >
      <Section title="Exporter un fichier de données">
        <p>
          Au cours de l'étude, vous pouvez exporter les données au format{" "}
          <b>.csv</b> ou <b>.xlsx</b> afin de les visualiser, les traiter, les
          analyser, etc... . Pour cela, dirigez-vous dans le menu <b>Analyse</b>{" "}
          puis dans l'onglet <b>Exports</b>.
        </p>
        <img src={ongletexport} alt="Onglet Exports" className="centered" />
        <p>
          Cliquez sur <b>Nouvel export</b> afin de faire une demande d'export.
          Le serveur traite alors votre demande, cela peut prendre quelques
          minutes.
        </p>
        <img src={nouvelexport} alt="Nouvel export" className="centered" />
        <Note type="note">
          <p>
            Si la demande d'export n'apparaît pas immédiatement, cliquez sur le
            bouton "<b>Rechargement</b>" en bas de la liste.
          </p>
        </Note>
      </Section>
      <Section title="Imprimer un formulaire">
        <p>
          Pour imprimer les données d'un formulaire au format parpier,
          rendez-vous sur le formulaire que vous souhaitez exporter puis cliquez
          sur l'icône <b>imprimante</b> qui s'affiche en haut à droite du
          formulaire.
        </p>
        <img src={imprimer} alt="Imprimer un formulaire" className="centered" />
      </Section>
    </UsingDotterLayout>
  );
}
