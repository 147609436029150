import { exist } from "@ki2/utils";
import clx from "clsx";

import { useShowable, useWindow } from "@helpers";

import "./Scroll.style.scss";

import img from "@images/scrollbutton.png";

interface IScrollProps {
  showBelow: number;
}

export function Scroll({ showBelow }: IScrollProps) {
  const show = useShowable(showBelow);
  const curWindow = useWindow();

  const handleClick = () => {
    if (exist(curWindow)) {
      window.scrollTo({ top: 0, behavior: `smooth` });
    }
  };

  return (
    <img
      src={img}
      className={clx("scrolltop-btn", {
        hide: show === false,
      })}
      onClick={handleClick}
    />
  );
}
