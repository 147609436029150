import completedsteps from "@content/tutorial/images/Completed steps-amico.svg";
import modeeditionsynopsis from "@content/advanced/images/modeeditionsynopsis.png";
import panneausynopsis from "@content/tutorial/images/panneausynopsis.png";
import editionphase from "@content/tutorial/images/edition-phase.png";
import ajouteretape from "@content/tutorial/images/ajouter-etapes.png";
import modeleassocie from "@content/tutorial/images/modele-associe.png";
import exempleetape from "@content/tutorial/images/exemple-etape.png";

import { Note, Section, SubSection } from "@components";

import { AtoZLayout } from "@content/tutorial/a-to-z/Layout";

export default function TutorialAtoZStep5() {
  return (
    <AtoZLayout
      lang="en"
      idx={5}
      heroUrl={completedsteps}
      heroAlt="Setting up the eCRF"
    >
      <p>
        Now that all the forms for the study are ready, the study synopsis can
        be created. Place the forms in the same order in which they appear in
        the course of the study. To access the study synopsis, stay in the{" "}
        <b>Study Editing</b> tab and switch to <b>Study Synopsis</b> mode by
        clicking the button at the top right of the screen.
      </p>
      <img
        src={modeeditionsynopsis}
        alt="Study Synopsis Mode"
        className="centered"
      />
      <p>
        The list on the left side of the screen shows the different phases of
        the study. Currently, there are three elements:
        <li>
          the <b>BASELINE</b> phase, which is the initial phase of the study. It
          may contain forms for inclusion, demographic data, or other legal
          documents (consent, etc.)
          <br />
          <em>
            (Note: if this phase remains empty, it will not appear in the study)
          </em>
        </li>
        <li>
          the <b>ADD A PHASE</b> button, which will allow you to add phases,
          i.e., specific time periods (temporal or not) for the study (e.g.,
          6-month follow-up) that can contain one or more steps associated with
          form templates.
        </li>
        <li>
          the <b>SPECIFIC FORMS</b> that will intervene outside the normal flow
          of the study{" "}
          <em>
            (for example, forms for study exit, reporting adverse events, etc.)
          </em>
        </li>
      </p>
      <img
        src={panneausynopsis}
        alt="Study Synopsis Panel"
        className="centered"
      />
      <p>
        As seen in the previous chapter, in Dotter.science we can break down
        each phase into steps. Here, a "step" incorporates a single form
        template, but a form template can be used within multiple steps. The aim
        of this chapter is to learn how to insert forms into the phases of the
        study and how to easily reprogram them.
      </p>

      <Section title="Create Phases and Steps">
        <SubSection title="Create a Phase">
          <p>
            First, click the <b>Add a Phase</b> button; the second part of the
            screen will change to display the configuration of this new phase.
          </p>
          <img src={editionphase} alt="Editing a Phase" className=" centered" />
          <p>
            Name this phase by hovering over the "<em>Unnamed Phase</em>" field
            and clicking the icon that appears.
          </p>
          <p>
            If this phase is to be repeated over time (every day, every week,
            etc.), choose a timing option and adjust the phase index according
            to its position.
          </p>
          <p>
            If this phase is repeatable, meaning it can be performed multiple
            times within the same timing, check the "<b>Repeatable Phase</b>"
            box and indicate the number of possible repetitions.
          </p>
          <Note type="tip">
            You will notice that the phase icon changes based on the parameters
            you assign. If you wish to modify this display, choose the{" "}
            <em>Custom</em> timing option. This way, you can select the letter
            that displays and the appropriate subtext.
          </Note>
          <p>
            Once you have finished configuring the phase, click the <b>Add</b>{" "}
            or <b>Edit</b> button that appears in green at the top of the
            screen.
          </p>
        </SubSection>
        <SubSection title="Create a Step">
          <p>
            Select the phase you wish to complete by clicking on it in the left
            panel. A <b>+ Add a Step</b> button will appear; click on it.
          </p>
          <img src={ajouteretape} alt="Add a Step" className="centered" />
          <p>
            The second panel changes again to display the editing of the current
            step. Name it by hovering over <em>Unnamed Step</em> and clicking
            the icon that appears.
          </p>
          <p>
            To associate a form with this step, you can choose to create a new
            complete form or retrieve an already created form template.
          </p>
          <img
            src={modeleassocie}
            alt="Model Associated with the Step"
            className="centered"
          />
          <Note type="note">
            For the purposes of this tutorial, we assume you have already
            created the form templates in the previous chapter, and we will use
            them directly here. If not, please refer to the chapter on{" "}
            <b>Designing the eCRF</b> which will explain how to create a form
            template.
          </Note>
          <p>
            Click on the icon "<b>Use an Existing Template</b>"; a window will
            open to display all the templates that have already been designed.
            Click on the one you wish to associate with the current step. The
            form and its content will then appear in the step creation area.
          </p>
          <img src={exempleetape} alt="Step Example" className="centered" />
          <p>
            If this step is repeatable, meaning it is performed multiple times
            during the same phase, you can check the "<em>Repeatable Step</em>"
            box and indicate the limit on possible repetitions.
          </p>
          <p>
            When you have finished editing the step, don’t forget to click the{" "}
            <b>Add</b> or <b>Edit</b> button at the top of the screen.
          </p>

          <p>
            Repeat the operations from this chapter until you obtain the
            complete eCRF for your study before moving on to its production
            phase.
          </p>
        </SubSection>
      </Section>
    </AtoZLayout>
  );
}
