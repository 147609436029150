import type { ReactNode } from "react";

interface ISectionProps {
  title: ReactNode;
  id?: string;
  children: ReactNode;
  className?: string;
}

export function Section({ title, id, children, className }: ISectionProps) {
  return (
    <section className={className}>
      <h2 id={id}>{title}</h2>
      {children}
    </section>
  );
}
