import { Fragment } from "react";
import type { ITutorialItem } from "@helpers";

import { TutorialProgressStep } from "./TutorialProgressStep";

import "./TutorialProgress.style.scss";

interface ITutorialProgressProps {
  items: ITutorialItem[];
  currentIndex: number;
}

export function TutorialProgress({
  items,
  currentIndex,
}: ITutorialProgressProps) {
  if (items.length === 0) {
    return <Fragment></Fragment>;
  }

  const xcontent = items.map((item) => (
    <TutorialProgressStep
      key={item.stepid}
      id={item.stepid}
      isActive={item.stepid === currentIndex}
      label={item.title}
      href={item.link}
    />
  ));

  return (
    <div className="tutorial-progress-root">
      <div className="progress-line"></div>
      <div className="tutorial-progress">{xcontent}</div>
    </div>
  );
}
