import { AdminPanelSettings } from "@mui/icons-material";

import { PageLayout, Section, ResponsiveTable } from "@components";

import "@styles/Page.scss";

export default function GuideRoles() {
  return (
    <PageLayout
      title="Roles and Permissions"
      titleIcon={<AdminPanelSettings />}
      titleClassName="guide"
      lang="en"
    >
      <Section id="roles" title="Roles">
        <p>
          On Dotter.science, each user has one or more role(s) that allow them
          to access certain features. Permissions are therefore restricted based
          on your role in the study to ensure optimal data security.
        </p>
        <p>
          The available roles are <b>Clinical Study Technician (CST)</b>,{" "}
          <b>Clinical Research Associate (CRA)</b>, <b>Investigator</b>, and{" "}
          <b>Administrator</b>.
        </p>
        <ResponsiveTable>
          <tr>
            <td>Role</td>
            <td>Role Description</td>
          </tr>
          <tr>
            <td>
              <b style={{ color: "#ffba00" }}>
                Clinical Study Technician (CST)
              </b>
            </td>
            <td>
              CSTs can list patients from their center, add new patients
              (screening), register forms, and lock them.
              <br />
              CST+ have full access to all centers. They have the same rights as
              CSTs, but for all the patients in the study. When adding a
              patient, CST+ can select the patient's affiliated center.
            </td>
          </tr>
          <tr>
            <td>
              <b style={{ color: "#59df6f" }}>
                Clinical Research Associate (CRA)
              </b>
            </td>
            <td>
              CRAs can list patients from their center but cannot add them or
              register forms. However, they can open and close queries, lock and
              unlock forms, and monitor (or unmonitor) forms.
              <br />
              Coordinating CRAs have the same rights as CRAs but for all the
              patients in the study. They can also perform a database export.
            </td>
          </tr>
          <tr>
            <td>
              <b style={{ color: "#9c1bcf" }}>Investigator</b>
            </td>
            <td>
              Investigators can list patients at their center, add them
              (screening), and register forms. They can also lock and sign
              forms.
              <br /> Coordinating investigators have the same rights as
              investigators but for all the patients in the study.
            </td>
          </tr>
          <tr>
            <td>
              <b style={{ color: "#fa383e" }}>Administrator</b>
            </td>
            <td>
              Local administrators (with limited access to one affiliated
              center) can perform all operations (including study administration
              and design) except for database export.
              <br />
              Global administrators (with full access to all centers) can
              perform all operations (including database export).
            </td>
          </tr>
        </ResponsiveTable>
      </Section>

      <Section id="permissions" title="Permissions">
        <p>
          The table below summarizes the permissions for each role. If a role
          has the described permission, a symbol <b>✔</b> is indicated.
        </p>
        <ResponsiveTable tableClassName="table-centered-cells">
          <tr className="vertical-headers">
            <td></td>
            <td>
              <div>CST</div>
            </td>
            <td>
              <div>CST+</div>
            </td>
            <td>
              <div>CRA</div>
            </td>
            <td>
              <div>Coordinating CRA</div>
            </td>
            <td>
              <div>Investigator</div>
            </td>
            <td>
              <div>Coordinating Investigator</div>
            </td>
            <td>
              <div>Local Administrator</div>
            </td>
            <td>
              <div>Global Administrator</div>
            </td>
          </tr>
          <tr>
            <td>List study patients (or patients from their center)</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Add a patient</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Save form data</td>
            <td>✔</td>
            <td></td>
            <td>✔</td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>
              Open and close queries (note: all users can respond to a query)
            </td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Lock a form</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Unlock a form</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Monitor a form</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Unmonitor a form</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Sign a form</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Remove the signature from a form</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>
              Administer the study (modify information, structure, centers, and
              study users)
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>Request a new export and view the list of exports</td>
            <td></td>
            <td></td>
            <td></td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
            <td>✔</td>
          </tr>
        </ResponsiveTable>
        <br />
      </Section>
    </PageLayout>
  );
}
