import type { ReactNode } from "react";
import clx from "clsx";

interface IResponsiveTableProps {
  children: ReactNode;
  className?: string;
  tableClassName?: string;
}

export function ResponsiveTable({
  children,
  className,
  tableClassName,
}: IResponsiveTableProps) {
  return (
    <div className={clx("responsive-table", className)}>
      <table className={tableClassName}>{children}</table>
    </div>
  );
}
