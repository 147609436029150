import type { ReactNode } from "react";
import { exist } from "@ki2/utils";

import { useTranlations } from "@helpers";
import type { ITutorialItem, AppLanguages } from "@helpers";

import { PreviousNextButton } from "./PreviousNextButton";

import "./PreviousNext.style.scss";

interface IPreviousNextProps {
  currentIndex: number;
  items: ITutorialItem[];
  lang?: AppLanguages;
}

export function PreviousNext({
  currentIndex,
  items,
  lang,
}: IPreviousNextProps) {
  const tkeys = useTranlations(lang);

  let prevStep: ITutorialItem | undefined = undefined;
  let nextStep: ITutorialItem | undefined = undefined;

  if (currentIndex > 1) {
    prevStep = items[currentIndex - 2];
  }
  if (currentIndex < items.length) {
    nextStep = items[currentIndex];
  }

  if (!exist(prevStep) && !exist(nextStep)) {
    return null;
  }

  return (
    <div className="tutorial-previousnext-root">
      <PreviousNextButton item={prevStep} lang={lang} mode="previous" />
      <PreviousNextButton item={nextStep} lang={lang} mode="next" />
    </div>
  );
}
