import type { ReactNode } from "react";
import { exist } from "@ki2/utils";
import clx from "clsx";

import {
  ArrowCircleLeftOutlined as Left,
  ArrowCircleRightOutlined as Right,
} from "@mui/icons-material";

import type { AppLanguages, ITutorialItem } from "@helpers";
import { useTranlations } from "@helpers";

interface IPreviousNextButtonProps {
  lang?: AppLanguages;
  mode: "previous" | "next";
  item: ITutorialItem | undefined;
}

export function PreviousNextButton({
  item,
  mode,
  lang,
}: IPreviousNextButtonProps) {
  const tkeys = useTranlations(lang);

  if (!exist(item)) {
    return null;
  }

  let subClassName: string | undefined = undefined;
  let xIcon: ReactNode = undefined;
  let label: string = "";
  if (mode === "previous") {
    xIcon = <Left />;
    label = tkeys.general.previous;
    subClassName = "previous";
  } else {
    xIcon = <Right />;
    label = tkeys.general.next;
    subClassName = "next";
  }

  return (
    <a className={clx("button", subClassName)} href={item.link}>
      {xIcon}
      <div>
        <h5>{label}</h5>
        <h3>{item.title}</h3>
      </div>
    </a>
  );
}
