import type { ReactNode } from "react";
import { Fragment } from "react";

interface ISubSectionProps {
  title: string;
  id?: string;
  children?: ReactNode;
  titleClassName?: string;
}

export function SubSection({
  title,
  id,
  children,
  titleClassName,
}: ISubSectionProps) {
  return (
    <Fragment>
      <h3 id={id} className={titleClassName}>
        {title}
      </h3>
      {children}
    </Fragment>
  );
}
