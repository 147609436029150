import type { ReactNode } from "react";
import clx from "clsx";

import type { AppLanguages } from "@helpers";

import type { ILayoutProps } from "./Layout";
import { Layout } from "./Layout";

export interface IPageLayoutProps extends ILayoutProps {
  id?: string;
  titleIcon?: ReactNode;
  titleClassName?: string;
  bodyClassName?: string;
  defaultBodyClassName?: string;
  preBody?: ReactNode;
  postBody?: ReactNode;
  lang?: AppLanguages;
}

export function PageLayout({
  title,
  children,
  preBody,
  postBody,
  roles,
  id,
  titleIcon,
  titleClassName,
  defaultBodyClassName = "page-body",
  lang,
}: IPageLayoutProps) {
  return (
    <Layout title={title} roles={roles} lang={lang}>
      {preBody}
      <div className={defaultBodyClassName}>
        <div className={clx("page-title", titleClassName)}>
          {titleIcon}
          <h1 id={id}>{title}</h1>
        </div>
        {children}
      </div>
      {postBody}
    </Layout>
  );
}
