import ajout from "@content/advanced/images/ajout-fichiers.png";
import files from "@content/advanced/images/upload-files.png";
import consulter from "@content/advanced/images/files-consulter.png";
import liste from "@content/advanced/images/files-list.png";

import { PageLayout, Section, SubSection, Note } from "@components";

import "@styles/Page.scss";

export default function FeatFiles() {
  return (
    <PageLayout title="Fichiers associés à l'étude" roles={["admin"]} lang="fr">
      <p>
        Dans Dotter.science, il est possible d'ajouter des fichiers externes à
        votre eCRF (
        <em>
          Exemple : joindre un formulaire de consentement, un protocole
          expérimental, etc...
        </em>
        ) . Les fichiers s'ajoutent au niveau d'une section de formulaire. Il
        est également possible d'ajouter des <b>Fichiers globaux</b> à une étude
        mais pour cela il faudra en faire la demande au support de
        Dotter.science pour des raisons de sécurité.
      </p>
      <Section
        id="ajouter-un-fichier-à-une-section"
        title="Ajouter un fichier à une section"
      >
        <p>
          Accédez à la section à laquelle vous souhaitez ajouter un fichier
          depuis la conception de l'eCRF (menu <b>Conception</b> puis onglet{" "}
          <b>Édition de l'étude</b>). Dans l'édition du modèle de formulaire,
          cliquez sur l'icône <b>Ajouter des fichiers</b> en haut à droite de la
          section.
        </p>
        <img src={ajout} alt="Ajouter des fichiers" className="centered" />
        <p>
          Une fenêtre s'ouvre, vous pouvez alors donner un nom au fichier et
          indiquer le lien qui renvoie vers votre fichier. Lorsque vous avez
          terminé, appuyez sur <b>Valider</b>.
        </p>
        <img src={files} alt="Ajouter des fichiers" className="centered" />
        <p>
          Si le ou les fichiers ont bien été ajoutés, un chiffre en rouge
          affiche, par-dessus l'icône <b>Ajouter des fichiers</b>, le nombre de
          fichiers associés à la section.
        </p>
        <Note type="caution">
          N'oubliez pas de cliquer sur le bouton <b>ENREGISTRER</b> lorsque vous
          avez terminé de modifier votre modèle.
        </Note>
      </Section>
      <Section id="consulter-les-fichiers" title="Consulter les fichiers">
        <SubSection title="Fichiers spécifiques aux formulaires">
          <p>
            Lorsque des fichiers ont été associés à une section de formulaire,
            vous pouvez les consulter depuis le dossier d'un patient.
            Rendez-vous au niveau de la section concernée puis cliquez sur
            l'icone <b>Fichiers téléchargeables</b> en haut à droite de la
            section. Un chiffre indique le nombre de fichiers associés à cette
            section.
          </p>
          <img
            src={consulter}
            alt="Consulter les fichiers"
            className="centered"
          />
          <p>
            Une fenêtre s'ouvre et liste l'ensemble des fichiers associés à
            cette section. Cliquez sur le nom d'un fichier pour le télécharger.
          </p>
          <img src={liste} alt="Liste des fichiers" className="centered" />
        </SubSection>
        <SubSection title="Fichiers globaux de l'étude">
          <p>
            Pour consulter les fichiers qui sont généraux à l'étude, rendez-vous
            sur dans le menu <b>Live</b> et l'onglet <b>Suivi des patients</b>.
            Depuis la vue globale, cliquez sur le bouton{" "}
            <b>Fichiers de l'étude</b> qui se trouve en bas de la page. Une
            fenêtre s'ouvre pour liste l'ensemble des fichiers de l'étude.
            Cliquez sur le nom d'un fichier pour le télécharger.
          </p>
        </SubSection>
      </Section>
    </PageLayout>
  );
}
