import schema from "@content/advanced/images/alertes-schema.png";
import alertes from "@content/advanced/images/alertes.png";

import { WarningOutlined, ErrorOutlined } from "@mui/icons-material";

import {
  PageLayout,
  Section,
  SubSection,
  Note,
  ResponsiveTable,
} from "@components";

import "@styles/Page.scss";

export default function FeatTestsAlerts() {
  return (
    <PageLayout
      title="Consistency Checks and Alerts"
      roles={["admin"]}
      lang="en"
    >
      <Section
        id="fuctionnality-of-checks-and-alerts"
        title="Functionality of Checks and Alerts"
      >
        <SubSection title="Principles of Checks">
          <p>
            <b>Consistency checks</b> are configurable parameters on each
            variable to define <b style={{ color: "#ffc100" }}>warning</b> or{" "}
            <b style={{ color: "#E74C3C" }}>critical error</b> thresholds. When
            a result is given for a variable, it is compared to the defined
            thresholds. If a threshold is exceeded, it triggers an <b>alert</b>.
          </p>
          <p>
            As shown in the diagram below, it is possible to define one-sided
            checks (
            <em>
              Example: the result is an outlier only if it exceeds the threshold
            </em>
            ), as in <b>a.</b>, or two-sided checks (
            <em>
              Example: the result is an outlier if it is below or above the
              thresholds set around the expected value.
            </em>
            ), as in <b>b.</b>
          </p>
          <img
            src={schema}
            className="centered"
            alt="Functionality of Alerts"
            style={{ boxShadow: "none" }}
          />
        </SubSection>
        <SubSection title="Types of Alerts">
          <p>There are two types of configurable alerts:</p>
          <ResponsiveTable>
            <tr>
              <td>Alert</td>
              <td>Consequences</td>
            </tr>
            <tr>
              <td
                style={{
                  gap: 5,
                  textAlign: "center",
                  verticalAlign: "center",
                }}
              >
                <WarningOutlined style={{ color: "#ffc100" }} /> <b>Warning</b>
              </td>
              <td>
                Low-level alerts that can be ignored with a click on the icon
              </td>
            </tr>
            <tr>
              <td
                style={{
                  gap: 5,
                  textAlign: "center",
                  verticalAlign: "center",
                }}
              >
                <ErrorOutlined style={{ color: "#E74C3C" }} />{" "}
                <b style={{ whiteSpace: "nowrap" }}>Critical Error</b>
              </td>
              <td>
                High-level alerts that must be addressed to allow locking of the
                forms
              </td>
            </tr>
          </ResponsiveTable>
          <p>
            You can configure only one of the two types of alerts if desired.
          </p>
          <p>
            When an alert is triggered, it appears next to the filled field in
            the patient record. The presence of one or more alerts is also
            indicated on the <b>Patient Monitoring</b> page in the detailed view
            (see{" "}
            <a href="/en/advanced/monitoring/">
              <b>Global and Detailed Monitoring</b>
            </a>
            ).
          </p>
        </SubSection>
      </Section>
      <Section
        id="defining-an-alert-for-a-variable"
        title="Defining an Alert for a Variable"
      >
        <SubSection title="Accessing Variable Settings">
          <p>
            To define an alert, you must first access the settings of the
            desired variable. Go to the <em>Design</em> menu →{" "}
            <em>Study Editing</em> tab. Switch to "<b>Template Editing</b>"
            mode, then click the <b>Variables</b> tab to list all variables in
            the study. Click on the variable to configure, then in the window
            that opens, switch to the <b>(2) Advanced Settings</b> tab.
          </p>
        </SubSection>
        <SubSection title="Configuring Alerts">
          <p>
            In the sub-menu <b>Define Alerts for the Variable</b>, you have the
            choice between two types of alerts (see the section{" "}
            <a href="#functionality-of-checks-and-alerts">
              <b>Functionality of Checks and Alerts</b>
            </a>
            ).
          </p>
          <p>
            For the type of alert you want, choose a condition from the standard
            operators and then define the threshold value. You can also define
            another condition to frame the expected value bilaterally.
          </p>
          <img
            src={alertes}
            className="centered"
            alt="Functionality of Alerts"
          />
          <p>
            <em>
              Example: <br />
              According to the image above, the alert of type <b>
                Warning
              </b>{" "}
              will trigger (1) if the weight is greater than or equal to 120kg
              or less than or equal to 50kg.
              <br />
              The alert of type <b>Critical Error</b> will trigger (2) only if
              the weight is greater than or equal to 150kg.
              <br />
              The expected value for this variable is therefore between 50 and
              120kg.
            </em>
          </p>
          <Note type="caution">
            Once you have configured your alerts, don't forget to save the
            variable for the alerts to be applied.
          </Note>
        </SubSection>
      </Section>
    </PageLayout>
  );
}
