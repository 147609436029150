import ecrf from "@content/tutorial/images/Audit-amico.svg";
import schemaecrf from "@content/tutorial/images/schema-ecrf.png";
import studyedit from "@content/advanced/images/acces-edition-etude.png";
import switchbutton from "@content/advanced/images/modeeditionmodele.png";
import typesvariable from "@content/advanced/images/typevariable.png";
import gotovariables from "@content/tutorial/images/goto-variables.png";
import gotomodels from "@content/tutorial/images/goto-models.png";
import editmodelname from "@content/tutorial/images/editmodelname.png";
import editionsection from "@content/tutorial/images/edition-section.png";
import ajoutcondtionnav from "@content/tutorial/images/ajouter-navigation-conditionnelle.png";
import conditions from "@content/tutorial/images/conditions-dactivation.png";

import { Note, Section, SubSection } from "@components";

import { AtoZLayout } from "@content/tutorial/a-to-z/Layout";

export default function TutorialAtoZStep4() {
  return (
    <AtoZLayout lang="fr" idx={4} heroUrl={ecrf} heroAlt="eCRF">
      <Section title="Comprendre le fonctionnement d'un eCRF sur Dotter.science">
        <p>
          Sur Dotter.science, l'eCRF est un ensemble de <b>formulaires</b> qui
          s'intègrent à chaque phase de l'étude sous le terme de "
          <b>modèles de formulaires</b>
          ". Ainsi, on peut créer des formulaires types qui pourront servir
          plusieurs fois à différents moments de l'étude sans avoir à les
          recréer à chaque fois.
        </p>
        <p>
          Les formulaires eux, contiennent une succession de questions ou
          mesures que l'on appelle ici "<b>variables</b>". Ces variables, comme
          les formulaires, sont stockées dans une base sous le terme de "
          <b>modèles de variables</b>" et peuvent être réutilisées à plusieurs
          moments de l'étude.
        </p>
        <img
          src={schemaecrf}
          alt="Schéma de l'eCRF"
          className="fullwidth"
          style={{ boxShadow: "none" }}
        />
        <p>
          Dans l'ordre, il faudra donc créer l'ensemble de vos variables, puis
          les insérer dans des modèles de formulaires et enfin intégrer ces
          modèles de formulaires dans la chronologie de l'étude. C'est de cette
          façon que nous procèderons dans la suite de ce tutoriel.
        </p>
      </Section>
      <Section title="Création des modèles">
        <p>
          La création de modèles, que ce soit pour les variables ou pour les
          formulaires, se déroulera dans la même interface de Dotter.science.
          Pour se faire, rendez-vous dans le menu <b>Conception</b> et sur
          l'onglet <b>Édition de l'étude</b>
        </p>
        <img src={studyedit} alt="Onglet schéma d'étude" className="centered" />
        <p>
          Passez l'interface en mode <b>Édition des modèles</b> en cliquant sur
          l'interrupteur qui se trouve en haut à droite de votre écran.
        </p>
        <img
          src={switchbutton}
          alt="Passer en mode édition des modèles"
          className="centered"
        />
        <SubSection title="Modèles de variables">
          <p>
            Commencez par créer les variables qui seront utilisées dans vos
            formulaires. Passez sur la liste des variables en cliquant sur{" "}
            <b>Variables</b> en bas de l'écran.
          </p>
          <img
            src={gotovariables}
            alt="Liste des variables"
            className="centered"
          />
          <p>
            L'ensemble des variables que vous aurez créé apparaîtra dans la zone
            à gauche. Pour y accéder et les modifier, il vous suffira de cliquer
            dessus. Commençons par créer la première variable. Pour se faire,
            cliquez sur le bouton "<b>+</b>" qui se trouve en haut de la liste.
            Une fenêtre s'ouvre alors pour vous proposer plusieurs types de
            variables.
          </p>
          <img
            src={typesvariable}
            alt="Types de variables"
            className="centered"
          />
          <p>
            Cliquez sur le type de variable qui vous convient, une nouvelle
            fenêtre s'affiche pour la configurer. Pour les variables de type QCM
            et calcul, il vous faudra obligatoirement configurer l'onglet{" "}
            <b>Paramètres avancés</b>, pour les autres ce n'est pas obligatoire.
          </p>
          <Note type="tip">
            Pour mieux comprendre comment utiliser les variables et aller plus
            loin dans leur configuration (définir des valeurs par défaut, des
            bornes, des alertes, etc...), vous pouvez vous rendre sur la
            documentation spécifique des{" "}
            <a href="/fr/advanced/variables" target="_blank">
              Variables (simples)
            </a>
            .
          </Note>
          <p>
            Répétez l'opération pour chaque variable que vous utiliserez dans
            votre eCRF avant de passer à la réalisation des modèles de
            formulaires.
          </p>
        </SubSection>
      </Section>
      <Section title="Modèles de formulaires">
        <p>
          Vous venez de créer un ensemble de variables qu'il va falloir
          maintenant insérer dans vos formulaires. Passez dans l'édition des
          modèles en cliquant sur le bouton <b>Modèles</b> en bas de l'écran.
        </p>
        <img src={gotomodels} alt="Liste des modèles" className="centered" />
        <p>
          Créez un nouveau modèle de formulaire en cliquant sur le bouton "
          <b>+</b>" qui se trouve en haut de la liste. La partie d'édition, à
          droite de l'écran, affiche alors le modèle de formulaire vide. Dans un
          premier temps, passez le curseur sur le nom du modèle puis cliquez sur
          le crayon qui s'affiche pour donner un nom à votre modèle de
          formulaire.
        </p>
        <img
          src={editmodelname}
          alt="Éditer le nom du modèle"
          className="centered"
        />
        <p>
          Vous pouvez ensuite préciser un type d'événement associé à ce
          formulaire, c'est-à-dire une conséquence qu'aura l'enregistrement de
          ce formulaire sur le dossier du patient (pour en savoir plus sur les
          types d'événements, consultez la documentation sur{" "}
          <a href="/fr/advanced/events">
            <b>les événements</b>).
          </a>
        </p>
        <p>
          Ajoutez une première section en cliquant sur le bouton "
          <b>+ Ajouter une section</b>". Vous pouvez alors lui donner un nom ou
          laisser le titre vide pour simplement espacer les champs du
          formulaire.
        </p>
        <p>
          Pour ajouter des variables à cette section, passez votre curseur sur
          la section pour faire apparaître deux boutons :
          <li>
            le premier vous permet de récupérer une variable que vous avez déjà
            créé précédemment
          </li>
          <li>le second vous permet de créer une nouvelle variable</li>
        </p>
        <img
          src={editionsection}
          alt="Insérer une variable"
          className="centered"
        />
        <p>
          Il est également possible d'activer certaines questions en fonction
          des réponses aux autres questions, c'est ce qu'on appelle la{" "}
          <a href="/fr/advanced/conditionnav">
            <b>navigation conditionnelle</b>
          </a>
          . Pour l'appliquer à une variable, glissez votre curseur dessus et
          cliquez sur l'icône <b>Modifier la condition d'activation</b>.
        </p>
        <img
          src={ajoutcondtionnav}
          alt="Modifier les conditions d'activation"
          className="centered"
        />
        <p>
          Dans la fenêtre qui s'ouvre, cliquez sur les flèches à côté de{" "}
          <em>Aucune variable sélectionnée</em>, puis choisissez la variable qui
          activera la variable actuelle. Cliquez ensuite sur le bouton{" "}
          <b>+ Condition </b> et définissez la condition de résultat qui
          activera la variable actuelle.
        </p>
        <img
          src={conditions}
          alt="Conditions d'activation"
          className="centered"
        />
        <p>
          Appuyez sur <b>Valider</b> pour confirmer votre choix. Une petite
          icône verte s'affiche à côté de votre variable pour indiquer qu'elle
          s'affichera bien de manière conditionnelle.
        </p>
        <Note type="tip">
          Pour mieux comprendre le fonctionnement des variables conditionnelles
          et améliorer leur rendu dans l'eCRF, vous pouvez lire la documentation
          sur la{" "}
          <a href="/fr/advanced/conditionnav">
            <b>Navigation conditionnelle</b>.
          </a>
        </Note>
        <p>
          Répétez l'opération pour ajouter les sections contenant les variables
          dont vous avez besoin. Lorsque votre modèle est terminé, cliquez sur
          le bouton "<b>ENREGISTRER</b>" en haut de l'écran.
        </p>
        <Note type="caution">
          Toute modification non enregistrée est <b>définitivement perdue</b>.
          Pensez à enregistrer votre modèle à la fin de chaque modification.
        </Note>
      </Section>
    </AtoZLayout>
  );
}
