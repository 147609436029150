import { useTranlations } from "@helpers";

import "./InterfaceInteractive.style.scss";

interface IInterfaceInteractiveProps {
  onClick: (currentarea: string) => void;
}

export function InterfaceInteractive({ onClick }: IInterfaceInteractiveProps) {
  const tkeys = useTranlations();

  function handleClick(currentarea: string) {
    onClick(currentarea);
  }

  return (
    <div className="interface-root">
      <div className="area-menu" onClick={() => handleClick("menu")}>
        <h3>{tkeys.interactiveUI.mainMenu}</h3>
      </div>
      <div className="area-top" onClick={() => handleClick("top")}>
        <h3>{tkeys.interactiveUI.topBar}</h3>
      </div>
      <div className="area-main" onClick={() => handleClick("main")}>
        <h3>{tkeys.interactiveUI.mainDisplay}</h3>
      </div>
      <div className="area-bottom" onClick={() => handleClick("bottom")}>
        <h3>{tkeys.interactiveUI.bottomBar}</h3>
      </div>
    </div>
  );
}
