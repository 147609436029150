import { useTranlations } from "@helpers";
import type { Roles, AppLanguages } from "@helpers";

import { AdminPanelSettings } from "@mui/icons-material";

import "./RequiredRoles.style.scss";

interface IRequiredRolesProps {
  roles?: Roles[];
  lang?: AppLanguages;
}

export function RequiredRoles({ roles = [], lang }: IRequiredRolesProps) {
  const tkeys = useTranlations(lang);

  if (roles.length === 0) {
    return null;
  }

  if (roles.includes("tec")) {
    roles.push("tec:local");
    roles.push("tec:global");
  }

  if (roles.includes("arc")) {
    roles.push("arc:local");
    roles.push("arc:global");
  }

  if (roles.includes("investigateur")) {
    roles.push("investigateur:local");
    roles.push("investigateur:global");
  }

  if (roles.includes("admin")) {
    roles.push("admin:local");
    roles.push("admin:global");
  }

  let rolesNames: string[] = [];

  if (roles.includes("tec:local")) {
    rolesNames.push(tkeys.userRoles.tec);
  }
  if (roles.includes("tec:global")) {
    rolesNames.push(tkeys.userRoles.tecp);
  }
  if (roles.includes("arc:local")) {
    rolesNames.push(tkeys.userRoles.arc);
  }
  if (roles.includes("arc:global")) {
    rolesNames.push(tkeys.userRoles.arcCoord);
  }
  if (roles.includes("investigateur:local")) {
    rolesNames.push(tkeys.userRoles.inv);
  }
  if (roles.includes("investigateur:global")) {
    rolesNames.push(tkeys.userRoles.invCoord);
  }
  if (roles.includes("admin:local")) {
    rolesNames.push(tkeys.userRoles.admLocal);
  }
  if (roles.includes("admin:global")) {
    rolesNames.push(tkeys.userRoles.admGlobal);
  }

  return (
    <div className="required-roles-container">
      <div className="required-roles-root">
        <div className="required-roles-title">
          <AdminPanelSettings />
          <p>
            <a href="/fr/guides/roles" target="_blank">
              <b>{tkeys.userRoles.required}</b>
            </a>
          </p>
        </div>
        <p className="required-roles-list">{rolesNames.join(", ")}</p>
      </div>
    </div>
  );
}
