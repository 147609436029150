import editionetude from "@content/advanced/images/acces-edition-etude.png";
import modemodele from "@content/advanced/images/modeeditionmodele.png";
import editionmodele from "@content/advanced/images/editionmodele.png";
import creersection from "@content/advanced/images/creationsection.png";
import files from "@content/advanced/images/upload-files.png";

import { PageLayout, Section, Note } from "@components";

import "@styles/Page.scss";

export default function FeatModels() {
  return (
    <PageLayout title="Modèles de formulaires" roles={["admin"]} lang="fr">
      <p>
        Les modèles vont vous permettre d'ajouter des variables à vos étapes. Il
        est possible d'utiliser un même modèle pour plusieurs étapes.
      </p>
      <Section
        id="accéder-à-l-edition-des-modèles"
        title="Accéder à l'édition des modèles"
      >
        <p>
          Rendez-vous dans la partie <b>Conception</b> puis{" "}
          <b>Édition de l'étude</b>.
        </p>
        <img
          src={editionetude}
          alt="Onglet édition de l'étude"
          className="centered"
        />
        <p>
          Passez l'interface en mode <b>Édition des modèles</b> en cliquant sur
          l'interrupteur qui se trouve en haut à droite de votre écran.
        </p>
        <img
          src={modemodele}
          alt="Passer en mode édition des modèles"
          className="centered"
        />
      </Section>
      <Section id="créer-un-modèle" title="Créer un modèle">
        <p>
          Cliquez sur <b>Ajouter un modèle</b>. Vous pouvez donner un nom à
          votre modèle et définir le type d'événement qui sera associé au
          formulaire.
        </p>
        <img src={editionmodele} alt="Nouveau modèle" className="centered" />
      </Section>
      <Section id="créer-une-section" title="Créer une section">
        <p>
          Cliquez sur le bouton <b>Ajouter une section</b>. Vous avez alors la
          possibilité de nommer votre section (facultatif).
        </p>
        <img src={creersection} alt="Nouvelle section" className="centered" />
        <p>
          Dans chaque section, vous pouvez ajouter des nouvelles{" "}
          <a href="/fr/advanced/variables">
            <b>variables</b>
          </a>{" "}
          ou des variables pré-enregistrées en cliquant sur le bouton "+" ou la
          loupe.
        </p>
        <Note type="note">
          Une variable est unique mais peut être utilisée plusieurs fois dans un
          même formulaire. Les données saisies seront synchronisées entre les
          deux champs affichés. Si une variable est réutilisée par erreur dans
          un même formulaire, elle sera mise en évidence par un cadre jaune.
        </Note>
        <p>
          Il est également possible d'associer des fichiers à la section que
          vous venez de créer en cliquant sur l'icône{" "}
          <b>Ajouter des fichiers</b> en haut à droite de la section.
        </p>
        <img src={files} alt="Ajouter des fichiers" className="centered" />
        <p>
          Une fenêtre s'ouvre, vous pouvez alors donner un nom au fichier et
          indiquer le lien qui renvoie vers votre fichier. Lorsque vous avez
          terminé, appuyez sur <b>Valider</b>.
        </p>
        <Note type="caution">
          N'oubliez pas de cliquer sur le bouton <b>ENREGISTRER</b> lorsque vous
          avez terminé de modifier votre modèle.
        </Note>
      </Section>
    </PageLayout>
  );
}
