import accesrandom from "@content/advanced/images/acces-schema-etude.png";
import choixrandom from "@content/advanced/images/choixrandomisation.png";
import ajoutergroupe from "@content/advanced/images/ajouter-groupe.png";
import bouton from "@content/advanced/images/randomize.png";

import { PageLayout, Section } from "@components";

import "@styles/Page.scss";

export default function FeatRandomization() {
  return (
    <PageLayout title="Randomization" roles={["admin"]} lang="en">
      <Section
        id="defining-the-randomization-of-a-study"
        title="Defining the Randomization of a Study"
      >
        <p>
          To configure the parameters related to randomization, go to the{" "}
          <b>Design</b> menu and then the <b>Study Schema</b> tab.
        </p>
        <img src={accesrandom} alt="Study Schema Tab" className="centered" />
        <p>
          Dotter.science can perform for 3 types of randomization:
          <li>Simple randomization</li>
          <li>Block randomization (fixed size)</li>
          <li>Block randomization (variable size)</li>
        </p>
        <p>
          To choose the desired randomization mode, click on{" "}
          <b>Choose a Randomization</b> (1) and select the one you want. In the
          case of block randomization, additional fields will appear to indicate
          the size of the blocks (2).
        </p>
        <img
          src={choixrandom}
          alt="Choosing Randomization"
          className="centered"
        />
      </Section>
      <Section id="creating-groups" title="Creating Groups">
        <p>
          To create the groups in which patients will be randomized, hover over
          the <b>Group Management</b> section and click the "<b>+</b>" button
          that appears. A window will then open to enter the group name. You can
          also add a short name and a description for each group, but this
          information is not mandatory and can be added later.
        </p>
        <img src={ajoutergroupe} alt="Add a Group" className="centered" />
      </Section>
      <Section id="randomizing-a-patient" title="Randomizing a Patient">
        <p>
          If your groups have been previously created, access the eCRF and then
          the data of a patient. At the bottom of the screen, a "
          <b>Randomize</b>" button will be displayed; when you click it, a
          confirmation window will appear. Confirm to randomize the patient and
          obtain the group to which they have been assigned.
        </p>
        <img src={bouton} alt="Randomize a Patient" className="centered" />
      </Section>
    </PageLayout>
  );
}
