import { useState, useEffect } from "react";
import Fuse from "fuse.js";
import { exist } from "@ki2/utils";

import {
  useTranlations,
  useLangRouteGetter,
  useCurrentLanguage,
} from "@helpers";
import type { AppLanguages } from "@helpers";

import { Search } from "@mui/icons-material";

import "./SearchBar.style.scss";

const options = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  minMatchCharLength: 1,
  keys: ["text"],
};

interface ISeachDBItem {
  _id?: string | undefined | null;
  text: string;
  route: string;
}

interface ISearchBarProps {
  lang?: AppLanguages;
}

export function SearchBar({ lang }: ISearchBarProps) {
  const [db, setDb] = useState([]);
  const [search, setSearch] = useState("");
  const [fuse] = useState(() => new Fuse<ISeachDBItem>(db, options));
  const curLang = useCurrentLanguage(lang);

  const getLangRoute = useLangRouteGetter();

  const tkeys = useTranlations(lang);

  const getCategoryKeys = (item: string) => {
    switch (item) {
      case "advanced":
        return tkeys.features;
      case "guides":
        return tkeys.guides;
      case "tutorials":
        return tkeys.tutorials;
      default:
        return [];
    }
  };

  useEffect(() => {
    const dbpath = `/static/searchDB.${curLang}.json`;
    console.log(`change db path to '${dbpath}'`);

    fetch(dbpath)
      .then((r) => r.json())
      .then((data) => {
        fuse.setCollection(data);
        setDb(data);
      });
  }, [curLang]);

  function setRoute(url: string) {
    const lroute = getLangRoute(url);

    let route = url.split("/");
    let path = "";
    switch (route[2]) {
      case "guides":
        path = tkeys.topMenu.guides;
        break;
      case "advanced":
        path = tkeys.topMenu.advancedFeatures;
        break;
    }

    let page = getCategoryKeys(route[2]).find((item) => {
      return item.link.endsWith(lroute.route);
    });

    let pagename = page?.name ?? tkeys.searchBar.noPage;

    let routeText = `${path} > ${pagename}`;
    return routeText;
  }

  let xResults;
  let isActive = "searchbar-block";
  if (search !== "") {
    isActive = "searchbar-block isActive";
    const filtered = fuse
      .search(search)
      .slice(0, 5)
      .map((item) => item.item);
    if (filtered.length === 0) {
      xResults = <p className="no-item-found">{tkeys.searchBar.noResult}</p>;
    } else {
      xResults = filtered.map((item) => {
        let id: string = "";
        if (exist(item._id)) {
          id = `#${item._id}`;
        }
        return (
          <a href={`${item.route}${id}`}>
            <p>{item.text}</p>
            <p>{setRoute(item.route)}</p>
          </a>
        );
      });
    }
  }

  return (
    <div className="searchbar-root">
      <div className={isActive}>
        <Search />
        <input
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          placeholder={tkeys.searchBar.placeholder}
        />
        <div className="searchbar-results">{xResults}</div>
      </div>
    </div>
  );
}
