import clx from "clsx";

interface ITutorialProgressStepProps {
  id: number;
  isActive?: boolean;
  label: string;
  href: string;
}

export function TutorialProgressStep({
  id,
  isActive: active,
  label,
  href,
}: ITutorialProgressStepProps) {
  return (
    <div
      className={clx("step", {
        active,
      })}
    >
      <a className="step-content" href={href}>
        <h3>{id}</h3>
        <h4>{label}</h4>
      </a>
    </div>
  );
}
