import { useEffect } from "react";
import { navigate } from "gatsby";

import { useDefaultLanguage } from "@helpers";

const IndexPage = () => {
  const defaultLanguage = useDefaultLanguage();

  useEffect(() => {
    navigate(`/${defaultLanguage}`);
  }, []);

  return null;
};

export default IndexPage;
