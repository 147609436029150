import { useLangRouteGenerator } from "@helpers";
import type { AppLanguages } from "@helpers";

import { Home } from "@mui/icons-material";

interface IHomeLinkProps {
  lang?: AppLanguages;
}

export function HomeLink({ lang }: IHomeLinkProps) {
  const genRoute = useLangRouteGenerator(lang);

  return (
    <a href={genRoute("/")} className="homebutton">
      <Home />
    </a>
  );
}
