import setup from "@content/tutorial/images/Setup-amico.svg";
import accesschemadetude from "@content/tutorial/images/acces-schema-etude.png";
import choixrandomisation from "@content/tutorial/images/choix-randomisation-simple.png";
import ajoutergroupe from "@content/tutorial/images/ajouter-groupe.png";
import formatpatient from "@content/tutorial/images/formatpatient.png";

import { Note, Section, SubSection, ResponsiveTable } from "@components";

import { AtoZLayout } from "@content/tutorial/a-to-z/Layout";

export default function TutorialAtoZStep3() {
  return (
    <AtoZLayout lang="en" idx={3} heroUrl={setup} heroAlt="Setup">
      <Section title="Configuring the Research Section">
        <p>
          Now let's move on to the research aspect. We will configure the
          technical points related to patient management, their distribution
          into groups, and their referencing in the study. To do this, go to the{" "}
          <b>Design</b> menu and then to the <b>Study Schema</b> tab.
        </p>
        <img
          src={accesschemadetude}
          alt="Study Schema Tab"
          className="centered"
        />

        <SubSection title="Choose the Randomization Mode">
          <p>
            In the <b>Research Design</b> block, you can choose to fill in the
            first three fields or fill them in later. Here, we will primarily
            focus on randomization. If you do not wish to randomize the patients
            in your study, you can skip this section and go directly to group
            management.
          </p>
          <img
            src={choixrandomisation}
            alt="Randomization Choice"
            className="centered"
          />
          <Note type="tip">
            The "Expected Sample Size" field allows you to set an inclusion
            target. If this field shows "0", this target will not be taken into
            account. If it is different, users will be able to see the progress
            of inclusions relative to this target in their interface.
          </Note>
          <p>
            In the <b>Choose a Randomization</b> field, select the type of
            randomization that meets your needs. Dotter.science offers 3 types
            of randomization.
          </p>
          <ResponsiveTable>
            <tr>
              <td>Type of Randomization</td>
              <td>Functionality</td>
            </tr>
            <tr>
              <td>
                None <br />
                (no groups or manual assignment)
              </td>
              <td>
                If only one group is planned in the study (no randomization in
                this case), or if assignment within groups must be done manually
                (via a dialog box).
              </td>
            </tr>
            <tr>
              <td>Simple</td>
              <td>
                Patients are randomly distributed among all created groups
                (randomization algorithm without balancing the groups).
              </td>
            </tr>
            <tr>
              <td>Block (fixed size)</td>
              <td>
                Patients are randomly assigned to groups while ensuring balance
                within each block, whose size is defined by the user.
              </td>
            </tr>
            <tr>
              <td>Block (variable size)</td>
              <td>
                Patients are randomly assigned to groups while ensuring balance
                within blocks of variable and unpredictable size (by setting the
                minimum and maximum size of the blocks).
              </td>
            </tr>
          </ResponsiveTable>
          <Note type="note">
            If multiple investigator centers participate in the study,
            randomization is automatically balanced within each center.
          </Note>
        </SubSection>
        <SubSection title="Creating Randomization Groups">
          <p>
            Once the type of randomization is chosen, you can create the groups
            into which the randomized patients will be assigned.
          </p>
          <p>
            In the <b>Group Management</b> section, click the "<b>+</b>" button,
            enter a name for the group, and click <b>Add</b>.
          </p>
          <img src={ajoutergroupe} alt="Add a Group" className="centered" />
          <p>
            Patients can be randomized among these groups by users once they
            have been included in the study.
          </p>

          <Note type="tip">
            To understand all the specifics of randomization, you can consult
            the advanced documentation by{" "}
            <a href="/en/advanced/randomization">clicking this link</a>.
          </Note>
        </SubSection>
        <SubSection title="Defining Patient References">
          <p>
            Within the study and in the exported data, patients will be
            referenced by a specific format to preserve their anonymity.
            Dotter.science allows you to define the format of this reference. To
            do this, go to the <b>Patient Reference Format</b> block.
          </p>
          <img
            src={formatpatient}
            alt="Patient Reference Format"
            className="centered"
          />
          <p>
            Patient references are divided into a chain of elements chosen from:
            <li>the patient's initials</li>
            <li>
              the name of the center they belong to (short name or reference)
            </li>
            <li>
              a number incremented according to patient's arrival in the study
              (general to the study or specific to the patient’s center)
            </li>
            <li>
              random characters (in a defined number) numeric or alphanumeric
            </li>
            <li>separators (predefined or custom)</li>
          </p>
          <p>
            To <b>add an element</b>, click the <b>+</b> button on the right of
            the chain and choose and set up the one you want.
            <br />
            To <b>remove an element</b>, hover over it and click the trash icon
            below.
            <br />
            To <b>move an element</b>, hover over it and click the arrows (left
            or right) that appear above.
          </p>
          <Note type="note">
            To insert an element at the beginning of the chain, you must first
            create it at the end of the chain and then move it using the arrows.
          </Note>
        </SubSection>
      </Section>
    </AtoZLayout>
  );
}
