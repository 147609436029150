import { ReactNode, useState, useEffect } from "react";
import { exist } from "@ki2/utils";

import {
  useTranlations,
  useLangRouteGenerator,
  useLangRoute,
  useHasWindow,
} from "@helpers";
import type { IMenuItem } from "@helpers";

import { Article } from "@mui/icons-material";

import "./RouteDisplay.style.scss";

export function RouteDisplay() {
  const langroute = useLangRoute();
  const genRoute = useLangRouteGenerator(langroute.lang);
  const hasWindow = useHasWindow();

  const [className, setClassName] = useState("");

  const tkeys = useTranlations();

  useEffect(() => {
    setClassName("route-display-root");
  }, []);

  if (!hasWindow) {
    return null;
  }

  const routearr: string[] = langroute.route.substring(1).split("/");

  if (routearr.length < 2) {
    // Don't display route if not in a page
    return null;
  }

  const category = routearr[0];

  let xCategory: ReactNode = undefined;
  let categoryKeys: IMenuItem[] = [];

  switch (category) {
    case "guides":
      categoryKeys = tkeys.guides;
      xCategory = <a href={genRoute("guides")}>{tkeys.topMenu.guides}</a>;
      break;
    case "advanced":
      categoryKeys = tkeys.features;
      xCategory = (
        <a href={genRoute("advanced")}>{tkeys.topMenu.advancedFeatures}</a>
      );
      break;
    default:
      // Don't display route for other categories
      return null;
  }

  const currentItem = categoryKeys.find((item) =>
    item.link.endsWith(langroute.route)
  );

  if (!exist(currentItem)) {
    // Page does not exist, don't display route
    console.log("no item found");
    console.log(langroute.route);
    console.log(categoryKeys);
    return null;
  }

  const pageName = currentItem.name;

  return (
    <div className={className}>
      <span>
        <Article />
      </span>
      <span>{xCategory}</span>
      <span> &gt; </span>
      {pageName}
    </div>
  );
}
