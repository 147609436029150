import { useState } from "react";

import { useTranlations, useLangRouteGenerator } from "@helpers";
import type { AppLanguages } from "@helpers";

import {
  Construction,
  School,
  PlayCircleOutline,
  EmojiObjects,
} from "@mui/icons-material";

import { AppIcon } from "../AppIcon";
import { SearchBar } from "./SearchBar";
import { LogoLink } from "./LogoLink";
import { HomeLink } from "./HomeLink";
import { NavMenu, NavMenuItem } from "./NavMenu";
import { NavMobileMenu, NavMobileMenuItem } from "./NavMobileMenu";

import "./NavBar.style.scss";

interface INavBarProps {
  lang?: AppLanguages;
}

export function NavBar({ lang }: INavBarProps) {
  const [menuStatus, setMenuStatus] = useState(false);
  const [menuClass, setMenuClass] = useState("menu-content close");
  const genRoute = useLangRouteGenerator(lang);

  const tkeys = useTranlations(lang);

  let xtutolist = tkeys.tutorials.map((item) => (
    <NavMenuItem
      key={item.link}
      href={item.link}
      icon={<PlayCircleOutline />}
      label={item.name}
    />
  ));

  let xguideslist = tkeys.guides.map((item) => (
    <NavMenuItem
      key={item.link}
      href={item.link}
      icon={<AppIcon name={item.icon} />}
      label={item.name}
    />
  ));

  let xfeatureslist = tkeys.features.map((item) => (
    <NavMenuItem
      key={item.link}
      href={item.link}
      icon={<AppIcon name={item.icon} />}
      label={item.name}
    />
  ));

  function handleMenu() {
    if (menuStatus === false) {
      setMenuClass("menu-content open");
      setMenuStatus(true);
    } else {
      setMenuClass("menu-content close");
      setMenuStatus(false);
    }
  }

  return (
    <div className="navbar-root">
      <LogoLink />
      <SearchBar lang={lang} />
      <div className="navbar-right">
        <NavMenu
          label={tkeys.topMenu.tutorials}
          icon={<School />}
          href={genRoute("tutorial")}
        >
          {xtutolist}
        </NavMenu>
        <NavMenu
          label={tkeys.topMenu.guides}
          icon={<EmojiObjects />}
          href={genRoute("guides")}
        >
          {xguideslist}
        </NavMenu>
        <NavMenu
          label={tkeys.topMenu.advancedFeatures}
          icon={<Construction />}
          href={genRoute("advanced")}
        >
          {xfeatureslist}
        </NavMenu>
        <HomeLink lang={lang} />
      </div>
      <NavMobileMenu
        label={tkeys.topMenu.menu}
        onClick={handleMenu}
        subClassName={menuClass}
      >
        <NavMobileMenuItem
          href={genRoute("tutorial")}
          label={tkeys.topMenu.tutorials}
          icon={<School />}
        />
        <NavMobileMenuItem
          href={genRoute("guides")}
          label={tkeys.topMenu.guides}
          icon={<EmojiObjects />}
        />
        <NavMobileMenuItem
          href={genRoute("advanced")}
          label={tkeys.topMenu.advancedFeatures}
          icon={<Construction />}
        />
      </NavMobileMenu>
    </div>
  );
}
