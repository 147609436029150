import { Helmet } from "react-helmet";

import favicon from "@images/logo-dotter-documentation-white.svg";

import { useTranlations } from "@helpers";
import type { Roles, AppLanguages } from "@helpers";

import { NavBar } from "./NavBar";
import { Scroll } from "./Scroll";
import { RouteDisplay } from "./RouteDisplay";
import { RequiredRoles } from "./RequiredRoles";

export interface ILayoutProps {
  title: string;
  children: any;
  roles?: Roles[];
  lang?: AppLanguages;
}

export function Layout({ title, children, roles, lang }: ILayoutProps) {
  const tkeys = useTranlations(lang);

  return (
    <div className="root">
      <Helmet>
        <title>
          {title} | {tkeys.general.appTitle}
        </title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:wght@400&family=Poppins:wght@500&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Crete+Round&display=swap"
          rel="stylesheet"
        ></link>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <NavBar lang={lang} />
      <Scroll showBelow={250} />

      <div className="root-sub-menu">
        <RouteDisplay />
        <div className="flex-fill" />
      </div>
      <div className="root-layout-content">
        <RequiredRoles roles={roles} lang={lang} />
        {children}
      </div>
    </div>
  );
}
