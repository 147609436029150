import interfacepatients from "@content/tutorial/images/interface-patients.png";
import interfacepatientsavance from "@content/tutorial/images/interface-patients-avance.png";
import jaugeglobale from "@content/advanced/images/jauge-globale.png";
import nouveaupatient from "@content/tutorial/images/nouveau-patient.png";
import ajoutpatient from "@content/tutorial/images/ajout-john-doe.png";
import confirmation from "@content/tutorial/images/ajout-patient-confirmation.png";

import {
  WarningOutlined,
  Report,
  SignalCellular4Bar,
  SignalCellular2Bar,
  Chat,
  GppGood,
  Lock,
} from "@mui/icons-material";

import { PageLayout, Section, Note, ResponsiveTable } from "@components";

import "@styles/Page.scss";

export default function FeatMonitoring() {
  return (
    <PageLayout title="Global and Detailed Monitoring" lang="en">
      <Section
        id="global-view-patient-monitoring"
        title="The Global Patient Monitoring View"
      >
        <p>
          The <b>Patient Monitoring</b> view (menu → <b>Live</b> tab) allows you
          to see all patients in the study, whether they are included or not.
        </p>
        <img
          src={interfacepatients}
          alt="Patient Monitoring Interface"
          className="fullwidth"
        />
        <p>
          On this page, you can:
          <li>
            <b>Access a patient's record</b>: by clicking on the corresponding
            row
          </li>
          <li>
            <b>Search for a patient</b> among all study patients: by clicking
            the magnifying glass at the top of the screen and typing their
            identifier
          </li>
          <li>
            <b>Know the overall progress</b> of inclusions: through the progress
            bar at the top of the screen (only displayed if a target is set)
          </li>
          <li>
            <b>Know the detailed progress</b> of the eCRF for each patient: by
            clicking the <em>Detailed View</em> button at the top of the screen,
            which shows the progress of each study phase for each patient
          </li>
        </p>
      </Section>
      <Section
        id="detailed-view-patient-monitoring"
        title="The Detailed Patient Monitoring View"
      >
        <img
          src={interfacepatientsavance}
          alt="Advanced Patient Monitoring Interface"
          className="centered"
        />
        <p>
          The detailed view provides an overview of form completion for all
          patients by phase. The progress of each phase is represented by an
          icon, which has the following meanings:
        </p>
        <ResponsiveTable>
          <tr>
            <td style={{ textAlign: "center" }}>Icon</td>
            <td>Icon Meaning</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <SignalCellular4Bar style={{ color: "#B1BABD" }} />
            </td>
            <td>Phase not started</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <SignalCellular2Bar style={{ color: "#3498DB" }} />
            </td>
            <td>Phase started</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <SignalCellular4Bar style={{ color: "#3498DB" }} />
            </td>
            <td>Phase completed</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <Lock style={{ color: "#1ABC9C" }} />
            </td>
            <td>Locked forms</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <GppGood style={{ color: "#27AE60" }} />
            </td>
            <td>Signed forms</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <SignalCellular2Bar style={{ color: "#ffc100" }} /> +{" "}
              <WarningOutlined style={{ color: "#ffc100" }} />
            </td>
            <td>A form contains a warning</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <SignalCellular2Bar style={{ color: "#E74C3C" }} /> +{" "}
              <Report style={{ color: "#E74C3C" }} />
            </td>
            <td>A form contains a critical error</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <SignalCellular2Bar style={{ color: "#3498DB" }} /> +{" "}
              <Chat style={{ color: "#B1BABD" }} />
            </td>
            <td>Queries have been raised</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <SignalCellular4Bar style={{ color: "#DFE3E4" }} />
              <SignalCellular2Bar style={{ color: "#ADD5F0" }} />
              <Lock style={{ color: "#A2E4D7" }} />
              <br />
              <span style={{ fontSize: "14px" }}>+ struck-out identifier</span>
            </td>
            <td>The patient has been excluded from the study</td>
          </tr>
        </ResponsiveTable>
        <Note type="tip">
          You can directly access a phase of the study for a given patient by
          clicking the corresponding icon.
        </Note>
      </Section>
      <Section id="inclusion-progress-bar" title="The Inclusion Progress Bar">
        <p>
          If your study has an inclusion target (set by the study
          administrator), a progress gauge will appear at the top of the{" "}
          <b>Patient Monitoring</b> page.
        </p>
        <img
          src={jaugeglobale}
          alt="Global Inclusion Gauge"
          className="centered"
        />
        <p>
          The legend is presented as bellow: <br />
          <span>
            <b>Number of currently included patients</b> /{" "}
            <b>Inclusion target</b> (<b>Inclusion progress</b> %).
          </span>
        </p>
        <Note type="note">
          If no inclusion target has been set for the study, the monitoring
          gauge will not be displayed.
        </Note>
      </Section>
      <Section id="adding-a-new-patient" title="Adding a New Patient">
        <p>
          From the <b>Patient Monitoring</b> view, click the <b>New Patient</b>{" "}
          button at the bottom of the screen.
        </p>
        <img src={nouveaupatient} alt="New Patient" className="centered" />
        <p>
          Fill in the patient's data and then click <b>Add</b> to validate the
          information.
        </p>
        <img src={ajoutpatient} alt="Add a Patient" className="centered" />
        <Note type="note">
          The length of the "<em>Patient Initials</em>" field may be limited as
          per the study requirements.
          <br />
          Guidelines on the order of initials may also have been configured and
          will appear next to the field if applicable.
        </Note>
        <p>
          A window will display the confirmation of the new patient addition.
          You can then choose to:
          <li>
            click <b>Go to Inclusion</b> to directly access the patient's
            inclusion form
          </li>
          <li>
            click <b>Back</b> to return to the patient list
          </li>
        </p>
        <img src={confirmation} alt="Confirmation" className="centered" />
      </Section>
    </PageLayout>
  );
}
