import ajout from "@content/advanced/images/ajout-fichiers.png";
import files from "@content/advanced/images/upload-files.png";
import consulter from "@content/advanced/images/files-consulter.png";
import liste from "@content/advanced/images/files-list.png";

import { PageLayout, Section, SubSection, Note } from "@components";

import "@styles/Page.scss";

export default function FeatFiles() {
  return (
    <PageLayout
      title="Files Associated with the Study"
      roles={["admin"]}
      lang="en"
    >
      <p>
        In Dotter.science, it is possible to add external files to your eCRF (
        <em>
          Example: attach a consent form, an experimental protocol, etc...
        </em>
        ). Files are added at the section level of a form. It is also possible
        to add <b>Global Files</b> to a study, but for security reasons this
        must be requested from Dotter.science support
      </p>
      <Section
        id="adding-a-file-to-a-section"
        title="Adding a File to a Section"
      >
        <p>
          Access the section where you want to add a file from the eCRF design
          (go to the <b>Design</b> menu and then the <b>Study Editing</b> tab).
          In the form template editing, click on the <b>Add Files</b> icon at
          the top right of the section.
        </p>
        <img src={ajout} alt="Add Files" className="centered" />
        <p>
          A window will open, allowing you to name the file and provide the link
          to your file. When you are finished, press <b>Validate</b>.
        </p>
        <img src={files} alt="Add Files" className="centered" />
        <p>
          If the file(s) have been added successfully, a red number will appear
          above the <b>Add Files</b> icon, indicating the number of files
          associated with the section.
        </p>
        <Note type="caution">
          Don’t forget to click the <b>SAVE</b> button when you have finished
          modifying your template.
        </Note>
      </Section>
      <Section id="viewing-files" title="Viewing Files">
        <SubSection title="Files Specific to Forms">
          <p>
            When files have been associated with a form section, you can view
            them from a patient's folder. Navigate to the relevant section and
            click on the <b>Downloadable Files</b> icon at the top right of the
            section. A number indicates the number of files associated with this
            section.
          </p>
          <img src={consulter} alt="View Files" className="centered" />
          <p>
            A window will open listing all files associated with this section.
            Click on the name of a file to download it.
          </p>
          <img src={liste} alt="List of Files" className="centered" />
        </SubSection>
        <SubSection title="Global Files for the Study">
          <p>
            To view the files that are general to the study, go to the{" "}
            <b>Live</b> menu and the <b>Patient Monitoring</b> tab. From the
            global view, click on the <b>Study Files</b> button located at the
            bottom of the page. A window will open listing all files for the
            study. Click on the name of a file to download it.
          </p>
        </SubSection>
      </Section>
    </PageLayout>
  );
}
