import startimg from "@content/tutorial/images/Launching-pana.svg";

import { Note, Section } from "@components";

import { UsingDotterLayout } from "@content/tutorial/using-dotter/Layout";

export default function TutorialUsingDotterStep1() {
  return (
    <UsingDotterLayout idx={1} lang="en">
      <p>
        This tutorial will help you understand the features of Dotter.science
        that will be useful for completing and managing the eCRF of your
        existing study. It is necessary for your study to be at least in the
        active state.
      </p>
      <p>
        If you are a user of the study, this tutorial is for you. If your study
        does not have an already constructed eCRF, and you are the study
        administrator wishing to create it, please refer to the tutorial{" "}
        <a href="/en/tutorial/a-to-z">
          <b>Designing a Study from A to Z</b>
        </a>
        .
      </p>
      <img src={startimg} alt="start" className="medium centered" />
      <Section title="Objective">
        <p>
          By the end of this tutorial, you will be able to:
          <li>
            add new patients to the study, include them, and randomize them
          </li>
          <li>navigate the existing eCRF to add data to the study</li>
          <li>initialize and visualize queries or events</li>
          <li>export data from the study</li>
        </p>
        <Note type="important">
          To complete this tutorial, you must have obtained access to a study
          from a Dotter.science administrator. If you have not done so, please
          request it by{" "}
          <a href="mailto:contact@dotter.science">clicking here</a>.
        </Note>
      </Section>
    </UsingDotterLayout>
  );
}
