import launch from "@content/tutorial/images/Launching-amico.svg";
import ongletadmin from "@content/tutorial/images/administratif.png";
import etatdeletude from "@content/tutorial/images/etat-de-l-etude.png";
import ongletexport from "@content/tutorial/images/acces-export.png";
import nouvelexport from "@content/tutorial/images/nouvel-export.png";

import { Note, Section, ResponsiveTable } from "@components";

import { AtoZLayout } from "@content/tutorial/a-to-z/Layout";

export default function TutorialAtoZStep6() {
  return (
    <AtoZLayout
      lang="fr"
      idx={6}
      heroUrl={launch}
      heroAlt="Démarrage de l'étude et exports"
    >
      <Section title="Passer l'étude en production">
        <Note type="caution">
          Le passage de l'étude en production est pour l'instant{" "}
          <b>désactivé</b> pour les administrateurs de l'étude. Pour modifier
          l'état de l'étude{" "}
          <a href="mailto:contact@dotter.sience">contactez un administrateur</a>{" "}
          de Dotter.science.
        </Note>
        <p>
          Lorsque votre eCRF est prêt, vous pouvez passer votre étude en
          production pour démarrer les inclusions et entrer les premières
          données. Rendez-vous dans le menu <b>Conception</b> et sur l'onglet{" "}
          <b>Administratif</b>.
          <img
            src={ongletadmin}
            alt="Onglet Administratif"
            className="centered"
          />
        </p>

        <p>
          Allez au niveau du bloc <b>État de l'étude</b>. Pour le moment, votre
          étude est en phase de <b>Conception</b>. Pour la passer en production,
          cliquez sur l'état <b>Active</b>. Une notification vous notifie que
          l'état de l'étude a bien été réalisé.
        </p>
        <img src={etatdeletude} alt="État de l'étude" className="centered" />
        <ResponsiveTable>
          <tr>
            <td>Statut</td>
            <td>Signification</td>
          </tr>
          <tr>
            <td>Conception</td>
            <td>
              Permet d'éditer l'eCRF et de créer des patients "fictifs"
              permettant de tester le bon fonctionnement des formulaires de
              recueil
            </td>
          </tr>
          <tr>
            <td>Active</td>
            <td>
              L'eCRF est validé, période d'inclusion et d'entrée des données
              d'étude
            </td>
          </tr>
          <tr>
            <td>Analyse</td>
            <td>
              Fin des inclusions, les données sont accessibles pour analyses
              statistiques
            </td>
          </tr>
          <tr>
            <td>Archivée</td>
            <td>
              Les données sont compilées et accessibles uniquement sur demande
              (une fonction <em>Terminée</em> intermédiaire est disponible
            </td>
          </tr>
        </ResponsiveTable>
        <Note type="tip">
          Si au cours de l'étude, vous souhaitez mettre en pause l'étude,
          cliquez sur le petit icône <em>Pause</em> sous l'état <em>Active</em>.
          L'entrée de données sera alors bloquée jusqu'à repasser l'étude à
          l'état actif.
        </Note>
      </Section>
      <Section title="Exporter les données d'étude">
        <p>
          Au cours de l'étude, vous pouvez exporter les données au format{" "}
          <b>.csv</b> ou <b>.xlsx</b> afin de les visualiser, les traiter, les
          analyser, etc.. Pour cela, dirigez-vous dans le menu <b>Analyse</b>{" "}
          puis dans l'onglet <b>Exports</b>.
        </p>
        <img src={ongletexport} alt="Onglet Exports" className="centered" />
        <p>
          Cliquez sur <b>Nouvel export</b> afin de faire une demande d'export.
          Le serveur traite alors votre demande, cela peut prendre quelques
          minutes.
        </p>
        <img src={nouvelexport} alt="Nouvel export" className="centered" />
        <Note type="note">
          <p>
            Si la demande d'export n'apparaît pas immédiatement, cliquez sur le
            bouton "<b>Rechargement</b>" en bas de la liste.
          </p>
        </Note>
      </Section>
    </AtoZLayout>
  );
}
