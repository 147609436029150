import inclusion from "@content/tutorial/images/Completed-amico.svg";
import suivipatients from "@content/tutorial/images/suivi-patients.png";
import nouveaupatient from "@content/tutorial/images/nouveau-patient.png";
import ajoutpatient from "@content/tutorial/images/ajout-john-doe.png";
import confirmation from "@content/tutorial/images/ajout-patient-confirmation.png";
import etapeinclusion from "@content/tutorial/images/etape-inclusion.png";
import popupinclusion from "@content/tutorial/images/confirmation-inclusion.png";
import randomize from "@content/advanced/images/randomize.png";

import { Note, Section } from "@components";

import { UsingDotterLayout } from "@content/tutorial/using-dotter/Layout";

export default function TutorialUsingDotterStep2() {
  return (
    <UsingDotterLayout
      idx={2}
      lang="fr"
      heroUrl={inclusion}
      heroAlt="Inclusions"
    >
      <Section title="Ajouter des patients">
        <p>
          Votre étude est en production et vous souhaitez ajouter vos premiers
          patients à l'étude. Pour celà rendez-vous dans le menu <b>Live</b>{" "}
          puis l'onglet <b>Suivi des patients</b>.
        </p>
        <img
          src={suivipatients}
          alt="Onglet suivi des patients"
          className="centered"
        />
        <p>
          La liste des patients de l'étude en cours s'affiche.Pour ajouter un
          nouveau patient, cliquer sur le bouton <b>Nouveau Patient</b> en bas
          de l'écran.
        </p>
        <img src={nouveaupatient} alt="Nouveau patient" className="centered" />
        <p>
          Remplissez à présent les données du patient puis cliquez sur{" "}
          <b>Ajouter</b> pour valider les informations.
        </p>

        <img src={ajoutpatient} alt="Ajouter un patient" className="centered" />
        <Note type="note">
          Il se peut que la longueur du champ "<em>Initiales du patient</em>"
          soit limité à la demande selon l'étude.
          <br />
          Des indications sur l'ordre des initiales peuvent également avoir été
          configurées et s'afficheront à côté du champ si c'est le cas.
        </Note>
        <p>
          Une fenêtre affiche la confirmation de l'ajout du nouveau patient.
          Vous avez alors le choix de :
          <li>
            cliquer sur <b>Aller à l'inclusion</b> pour accéder directement au
            formulaire d'inclusion du patient
          </li>
          <li>
            cliquer sur <b>Retour</b> pour revenir à la liste des patients
          </li>
        </p>
        <img src={confirmation} alt="Confirmation" className="centered" />
      </Section>
      <Section title="Inclure un patient à l'étude">
        <p>
          Il existe deux façons d'accéder à l'inclusion d'un patient :
          <li>
            soit vous avez cliqué sur <b>Aller à l'inclusion</b> suite à l'ajout
            d'un nouveau patient
          </li>
          <li>
            soit vous êtes revenu à la liste des patients, auquel cas cliquez
            sur le patient à inclure pour accéder à son dossier puis
            sélectionnez l'étape qui contient le formulaire d'inclusion.
          </li>
        </p>
        <img
          src={etapeinclusion}
          alt="Étape d'inclusion"
          className="centered"
        />
        <p>
          Le formulaire d'inclusion apparaît. Complétez le formulaire avec les
          données de votre patient puis validez le formulaire en cliquant sur le
          bouton <b>Enregistrer</b> en bas de l'écran. Une fenêtre s'ouvre pour
          vous indiquer que la validation de ce formulaire entraînera
          l'inclusion ou l'exclusion du patient à l'étude (en fonction des
          réponses au formulaire). Cliquez sur <b>Inclure</b> ou <b>Exclure</b>{" "}
          pour valider votre choix.
        </p>
        <img
          src={popupinclusion}
          alt="Confirmation de l'inclusion"
          className="centered"
        />
        <Note type="caution">
          Une fois un patient inclu, vous ne pouvez plus modifier son statut
          d'inclusion à moins qu'un événement d'exclusion ne survienne au cours
          de l'étude.
        </Note>
        <Note type="tip">
          Si un objectif d'inclusions a été configuré pour votre étude, une
          barre de progression vous permet de connaître l'avancement par rapport
          à cet objectif. Pour la visualiser, accédez à la liste des patients
          (onglet <b>Suivi des patients</b>), elle s'affiche alors en haut de
          l'écran.
        </Note>
      </Section>
      <Section title="Randomisation d'un patient">
        <p>
          Seul un patient inclu peut être randomisé. Si ce n'est pas le cas de
          votre patient référez vous au point précédent. <br /> Si le patient
          est déjà inclu, rendez-vous sur son dossier et cliquez sur le bouton{" "}
          <b>Randomiser</b> en bas de l'écran.
        </p>
        <img src={randomize} alt="Randomiser" className="centered" />
        <p>
          Une fenêtre différente apparaît en fonction du{" "}
          <a href="/fr/advanced/randomization">
            <b>type de randomisation</b>
          </a>{" "}
          choisi pour votre étude :
          <li>
            <em>Aucune ou attribution manuelle</em> : un champ vous permet de
            choisir le groupe auquel vous souhaitez attribuer le patient puis un
            rappel s'affiche pour confirmer votre choix
          </li>
          <li>
            Autre randomisation spécifique : la répartition se fait
            automatiquement et un message vous indique le résultat de la
            randomisation
          </li>
        </p>
      </Section>
    </UsingDotterLayout>
  );
}
