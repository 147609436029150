import studyexport from "@content/tutorial/images/Printer-amico.svg";
import ongletexport from "@content/tutorial/images/acces-export.png";
import nouvelexport from "@content/tutorial/images/nouvel-export.png";
import imprimer from "@content/advanced/images/imprimer.png";

import { Note, Section } from "@components";

import { UsingDotterLayout } from "@content/tutorial/using-dotter/Layout";

export default function TutorialUsingDotterStep6() {
  return (
    <UsingDotterLayout
      idx={6}
      lang="en"
      heroUrl={studyexport}
      heroAlt="Export the Study"
    >
      <Section title="Export a Data File">
        <p>
          During the study, you can export the data in <b>.csv</b> or{" "}
          <b>.xlsx</b> format to visualize, process, analyze, etc. To do so, go
          to the <b>Analysis</b> menu and then to the <b>Exports</b> tab.
        </p>
        <img src={ongletexport} alt="Exports Tab" className="centered" />
        <p>
          Click on <b>New Export</b> to request an export. The server will
          process your request, which may take a few minutes.
        </p>
        <img src={nouvelexport} alt="New Export" className="centered" />
        <Note type="note">
          <p>
            If the export request does not appear immediately, click the "
            <b>Reload</b>" button at the bottom of the list.
          </p>
        </Note>
      </Section>
      <Section title="Print a Form">
        <p>
          To print the data of a form in paper format, go to the form you wish
          to export and click on the <b>printer</b> icon that appears at the top
          right of the form.
        </p>
        <img src={imprimer} alt="Print a Form" className="centered" />
      </Section>
    </UsingDotterLayout>
  );
}
