import type { ReactNode } from "react";

import { useIsClient } from "@helpers";

interface INavMenuProps {
  label: ReactNode;
  icon?: ReactNode;
  href?: string;
  children: ReactNode;
}

export function NavMenu({ label, icon, href, children }: INavMenuProps) {
  return (
    <div className="navdrawer">
      <a href={href} className="navlink">
        {label} {icon}
      </a>
      <div className="navdrawer-content">{children}</div>
    </div>
  );
}

interface INavMenuItemProps {
  href: string;
  icon: ReactNode;
  label: string;
}

export function NavMenuItem({ href, icon, label }: INavMenuItemProps) {
  const isClient = useIsClient(); // Force re-render on client side to update href

  if (!isClient) {
    return null;
  }

  return (
    <a href={href} className="navlink-item">
      <li>
        <span>{icon}</span>
        <span>{label}</span>
      </li>
    </a>
  );
}
