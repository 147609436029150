import type { ReactNode } from "react";
import { Fragment } from "react";

interface IShowProps {
  when: boolean;
  children: ReactNode;
}

export function Show({ when, children }: IShowProps) {
  if (when) {
    return <Fragment>{children}</Fragment>;
  }
  return null;
}
