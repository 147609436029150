import { ReactNode } from "react";

interface IRootSelectorItemProps {
  children: ReactNode;
  src: string;
  alt: string;
  href: string;
}

export function RootSelectorItem({
  children,
  src,
  alt,
  href,
}: IRootSelectorItemProps) {
  return (
    <a className="index-element" href={href}>
      <img src={src} alt={alt} />
      <h1>{children}</h1>
    </a>
  );
}
