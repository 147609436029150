import type { ReactNode } from "react";

import { TutorialLayout } from "@components";

import type { AppLanguages } from "@helpers";

interface ILayoutProps {
  idx: number;
  lang: AppLanguages;
  children: ReactNode;
  heroUrl?: string;
  heroAlt?: string;
}

export function AtoZLayout({
  idx,
  lang,
  children,
  heroUrl,
  heroAlt,
}: ILayoutProps) {
  return (
    <TutorialLayout
      currentStepIndex={idx}
      lang={lang}
      selectedTutorial="AtoZ"
      heroAlt={heroAlt}
      heroUrl={heroUrl}
    >
      {children}
    </TutorialLayout>
  );
}
