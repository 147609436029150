import type { ReactNode } from "react";

import { PlayCircleOutlineOutlined as PlayCircleOutlineOutlinedIcon } from "@mui/icons-material";

interface ITutorialSelectorItemProps {
  title: string;
  src: string;
  alt?: string;
  href: string;
  children: ReactNode;
}

export function TutorialSelectorItem({
  title,
  src,
  alt,
  href,
  children,
}: ITutorialSelectorItemProps) {
  return (
    <a href={href} className="tutorial-block">
      <h2>{title}</h2>
      <div className="tutorial-block-desc">
        <img src={src} alt={alt ?? title} />
        {children}
        <PlayCircleOutlineOutlinedIcon />
      </div>
    </a>
  );
}
