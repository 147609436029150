import settings from "@content/tutorial/images/Settings-amico.svg";
import ongletadmin from "@content/tutorial/images/administratif.png";
import parametresgeneraux from "@content/tutorial/images/parametres-generaux.png";
import centresinvestigateurs from "@content/tutorial/images/ajouter-centre-investigateur.png";
import ajouterutilisateur from "@content/tutorial/images/ajouter-utilisateur.png";

import { AtoZLayout } from "@content/tutorial/a-to-z/Layout";

import { Note, Section, SubSection } from "@components";

export default function TutorialAtoZStep2() {
  return (
    <AtoZLayout lang="fr" idx={2} heroUrl={settings} heroAlt="Configurations">
      <Section title="Configuration de la partie administration">
        <p>
          Lorsque vous accédez à votre étude pour la première fois, vous devez
          au plus tôt paramétrer les informations d'ordre administratif. Cela
          permettra, lors des exports de données, d'avoir une plus grande
          traçabilité des informations.
        </p>
        <p>
          Pour cela, rendez-vous dans l'onglet <b>Conception</b> puis{" "}
          <b>Administratif</b> afin de pouvoir configurer votre étude.
        </p>
        <img
          src={ongletadmin}
          alt="Onglet administratif"
          className="centered"
        />

        <SubSection title="Configurer les paramètres généraux de l'étude">
          <p>
            Dans le bloc "<b>Paramètres généraux</b>", remplissez à minima les
            champs encadrés et marqués d'un "*", ils seront obligatoires pour
            progresser la conception de l'étude. Si vous disposez des
            informations supplémentaires (acronyme de l'étude, numéro ID RCB,
            ...) vous pouvez les compléter des à présent ou y revenir plus tard.
          </p>
          <img
            src={parametresgeneraux}
            alt="Onglet administratif"
            className="centered"
          />
          <p>
            Le champ <b>Classification</b> désigne le type d'étude clinique que
            vous réalisez parmi les choix suivants :
            <li>Recherche interventionnelle</li>
            <li>
              Recherche interventionnelle à risques et contraintes minimes
            </li>
            <li>Recherche non interventionnelle</li>
          </p>
          <p>
            Lorsque vous avez terminé, n'oubliez pas de cliquer sur "
            <b>Éditer</b>" pour valider vos modifications.
          </p>
        </SubSection>
        <SubSection title="Ajouter des centres investigateurs">
          <p>
            Une fois les informations administratives complétées, il vous faudra
            créer le ou les différents centres investigateurs participant à
            votre étude. La création de ces centres vous permettra d'organiser
            et répartir l'ensemble des utilisateurs et les patients participants
            à l'étude. Dans le bloc <b>Centre investigateurs</b> cliquez sur le
            bouton "<b>+</b>" et insérez son nom (obligatoire).
          </p>
          <img
            src={centresinvestigateurs}
            alt="Ajouter un centre investigateur"
            className="centered"
          />
          <Note type="note">
            Vous pouvez si vous le souhaitez ajouter :
            <li>
              <b>un acronyme</b> : il sera utilisé pour identifier le centre
              dans certaines listes et formulaires afin de simplifier
              l'affichage.
            </li>
            <li>
              <b>une référence</b> : elle sera utilisée lors des exports, pour
              corrélérer avec des références utilisées en interne par exemple.
            </li>
          </Note>
          <p>
            Répétez l'opération pour chaque centre investigateur associé à
            l'étude. Lorsque tous ont été ajoutés, vous pouvez passer à la
            gestion des utilisateurs.
          </p>
        </SubSection>
        <SubSection title="Inviter des utilisateurs">
          <p>
            L'ajout d'utlisateurs fonctionne avec un système d'invitation,
            lorsque vous ajoutez un utilisateur via Dotter.science, la personne
            en question recevra un email d'invitation afin de confirmer son
            ajout à l'étude, l'utilisateur doit accepter l'invitation afin
            d'être considéré comme ajouté à l'étude.
          </p>
          <p>
            Dans le bloc <b>Gestion des utilisateurs</b>, cliquez sur le bouton
            "<b>+</b>", renseignez les trois champs obligatoires qui vous sont
            proposés puis cliquez sur <b>Ajouter</b>. Si vous avez un doute sur
            le rôle d'un utilisateur, vous pouvez consulter le guide sur{" "}
            <a href="/fr/guides/roles" target="_blank">
              <b>les rôles</b>
            </a>
            .
          </p>
          <img
            src={ajouterutilisateur}
            alt="Ajouter un utilisateur"
            className="centered"
          />
          <Note type="caution">
            L'adresse mail doit être saisie dans un <b>format valide</b> et de
            préférence à <b>usage professionnel</b>. Tous les champs (adresse
            e-mail, centre de rattachement et rôle) doivent êtres remplis, même
            si le centre de rattachement est inexistant, le cas échéant
            sélectionnez "<em>Aucun</em>".
          </Note>
          <Note type="tip">
            Si un utilisateur a perdu le courriel d'invitation à l'étude, vous
            pouvez le renvoyer (dans la limite d'un envoi par jour) en cliquant
            sur le bouton d'édition de l'utilisateur puis "
            <em>Renvoyer un mail d'invitation à cet utilisateur</em>".
          </Note>
        </SubSection>
      </Section>
    </AtoZLayout>
  );
}
